import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import axios from "axios";
import { MarketContext } from "../Context";
import "../css/FormView.css";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import WorkInProgress from "./WorkInProgress";
import Header from "../small_compo/Header";

export default function FormViewComponent() {
  const { token, loca, user, appname } = useContext(MarketContext);

  const navigation = useNavigate();
  const [isClick, setIsClick] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [tab_mn, setTab_mn] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [tabname, setTabname] = useState(false);
  const [typename, setTypename] = useState(false);
  const [colState, setcolState] = useState(false);
  const [tabState, setTabState] = useState(true);
  const [col_mn, setCol_mn] = useState([]);
  const [columnarray2, setColumnarray2] = useState([""]);
  const [page_error, setPage_Error] = useState(false);
  const [error, setError] = useState("");
  const [warning, setWarning] = useState(false);
  const [json, setJson] = useState([]);
  const [flag, setFlag] = useState(false);
  const [viewList, setViewList] = useState([]);
  const [mncolor, setMncolor] = useState(false);
  const [isColArrSelected, setIsColArrSelected] = useState(false);
  const [isColArr2Selected, setIsColArr2Selected] = useState(false);
  const [isRefFieldSelected, setisRefFieldSelected] = useState(false);
  const [allcol_mn, setAllcol_mn] = useState(new Map());
  const [refCols, setrefCols] = useState(new Map());
  const [selectedRefTab, setselectedRefTab] = useState("");

  const ind = useRef(0);
  const index = useRef(0);
  const loc = useLocation().state;

  useEffect(() => {
    if (loc) {
      // setTabname(loc.tabName);
      setTabState(true);
      formChangeTable(loc.tabName);
      getFormViewRecord();
    } else {
      getFormViewRecord();
    }
  }, [refresh]);

  const getFormViewRecord = () => {
    setLoading(true);
    setTabState(false);
    setWarning(false);
    axios
      .get(loca + "/marketplace/get/tables", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((resp) => {
        const formdata = resp.data;
        if (formdata !== "") {
          if ("Error" in formdata) {
            setLoading(false);
            setPage_Error(true);
            setError(formdata.Error);
          } else {
            setLoading(false);
            setTabState(true);
            formdata.tableRecords.sort((a, b) => {
              return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
            });
            setTab_mn(formdata.tableRecords);
          }
        }
      });
  };
  const setCurrentRefCol_mn = (arr, key) => {
    let colm2 = columnarray2;
    for (let k = 0; k < arr.length; k++) {
      for (let i = 0; i < colm2.length; i++) {
        if (arr[k].key === colm2[i].key && arr[k].name === colm2[i].name) {
          arr[k].check = true;
          break;
        }
      }
    }
    return arr;
  };

  const formChangeTable = (val, dontFalse, key) => {
    const newMap = new Map(allcol_mn);
    if (newMap.has(key)) {
      setCol_mn(newMap.get(key));
      return;
    }
    if (!dontFalse) {
      setMncolor(false);
      setFlag(false);
    }

    setIsColArrSelected(false);
    setIsColArr2Selected(false);
    if (val !== "" && val !== "none") {
      setcolState(false);

      setCol_mn([]);
      if (!dontFalse) {
        setTabname(val);
        setColumnarray2([]);
        setViewList([]);
        const newMap2 = new Map(allcol_mn);
        newMap2.clear();
        setAllcol_mn(newMap2);
        const newMap3 = new Map(refCols);
        newMap3.clear();
        setrefCols(newMap3);
      }
      document.getElementById("one").value = "none";
      axios
        .get(loca + "/marketplace/get/columnview/" + val, {
          headers: {
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then((resp) => {
          const coldata = resp.data;
          if (coldata !== "") {
            if ("Error" in coldata) {
              setLoading(false);
              setPage_Error(true);
              setError(coldata.Error);
            } else {
              setcolState(true);
              let arr = [...coldata.colView[0].columnRecords];
              if (!dontFalse) {
                for (let pp = 0; pp < arr.length; pp++) {
                  arr[pp].key = val;
                }
                newMap.set(val, arr);
              } else if (!newMap.has(key)) {
                for (let pp = 0; pp < arr.length; pp++) {
                  arr[pp].key = key;
                }
                arr = setCurrentRefCol_mn(arr, key);
                newMap.set(key, arr);
              }

              setAllcol_mn(newMap);
              setCol_mn([...coldata.colView[0].columnRecords]);
              if (!dontFalse) {
                setViewList([...coldata.colView[1].viewRecords]);
              }

              if (coldata.colView[1].viewRecords.length === 0) {
                setWarning(true);
              } else {
                setWarning(true);
              }

              setMncolor(true);
            }
          }
        });
    }
  };
  const callReferenceCol = () => {
    setisRefFieldSelected(false);
    if (selectedRefTab) {
      let key2 = "";
      let st = "";
      const newMap = new Map(refCols);
      //    if (!newMap.has(selectedRefTab.refTbName)) {

      if (newMap.size === 0) {
        const key1 = selectedRefTab.tabName;
        newMap.set(key1, {
          one: selectedRefTab.tabLabel,
          two: st + selectedRefTab.tabLabel + " fields",
          path: selectedRefTab.tabName,
          tabPath: selectedRefTab.tabName,
        });
      }

      let lastKey;
      // let path = "";
      st = "";

      for (let [kk, value] of newMap) {
        lastKey = kk;
        st += ". ";
      }

      key2 = lastKey;
      let leb = selectedRefTab.label;
      leb = leb.substring(0, leb.length - 3);
      key2 += leb;

      newMap.set(key2, {
        one: leb,
        two: st + leb + " fields",
        path: selectedRefTab.name,
        tabPath: selectedRefTab.refTbName,
      });
      formChangeTable(selectedRefTab.refTbName, true, key2);
      setrefCols(newMap);
    }
  };

  const setColumn = (prefArray, cmm) => {
    let clm = col_mn;
    if (cmm) clm = cmm;

    let clm2 = [];

    for (var i = 0; i < clm.length; i++) {
      clm[i].check = false;
    }
    for (let i = 0; i < prefArray.length; i++) {
      for (let j = 0, cnt = 0; j < clm.length; j++) {
        if (clm[j].name === prefArray[i].name && !prefArray[i].secondary) {
          clm[j].check = true;
          let jj = {
            label: clm[j].label,
            name: clm[j].name,
            co: prefArray[i].co,
            tabName: prefArray[i].tabName,
            key: prefArray[i].key,
            path: prefArray[i].path,
            type: prefArray[i].type,
            colId: prefArray[i].colId,
            tabPath: prefArray[i].tabPath,
          };
          clm2.push(jj);
          break;
        }
        if (cnt === clm.length - 1) {
          if (prefArray[i].name === "--split--") {
            var jj = {
              label: prefArray[i].name,
              name: prefArray[i].name,
              co: prefArray[i].co,
            };
            clm2.push(jj);
            break;
          } else if (prefArray[i].name === "--split-start--") {
            var jj = {
              label: prefArray[i].name,
              name: prefArray[i].name,
              co: prefArray[i].co,
            };
            clm2.push(jj);
            break;
          } else if (prefArray[i].name === "--split-end--") {
            var jj = {
              label: prefArray[i].name,
              name: prefArray[i].name,
              co: prefArray[i].co,
            };
            clm2.push(jj);
            break;
          }
        }

        if (prefArray[i].secondary) {
          let jj = {
            label: prefArray[i].label,
            name: prefArray[i].name,
            co: prefArray[i].co,
            tabName: prefArray[i].tabName,
            key: prefArray[i].key,
            path: prefArray[i].path,
            type: prefArray[i].type,
            colId: prefArray[i].colId,
            secondary: prefArray[i].secondary,
            tabPath: prefArray[i].tabPath,
          };
          clm2.push(jj);
          break;
        }

        cnt++;
      }
    }
    if (clm[clm.length - 1].label !== "--split-end--") {
      clm.push({
        label: "--split-start--",
        type: "ui",
        name: "--split-start--",
        check: false,
      });
      clm.push({
        label: "--split--",
        type: "ui",
        name: "--split--",
        check: false,
      });
      clm.push({
        label: "--split-end--",
        type: "ui",
        name: "--split-end--",
        check: false,
      });
    }
    setCol_mn([...clm]);
    setColumnarray2([...clm2]);
    setFlag(true);
  };

  const formChangeType = (val) => {
    setIsColArrSelected(false);
    setIsColArr2Selected(false);
    if (val !== "" && val !== "none") {
      let obj = viewList.find((obj, ind) => obj.id === val).type;

      setTypename(obj);
      if (tabname !== "" && tabname !== "none") {
        axios
          .get(loca + "/marketplace/get/view/" + tabname + "/" + val, {
            headers: {
              authorization: "Bearer " + token,
              "market-application": appname,
            },
          })
          .then((resp) => {
            const data = resp.data;
            if (data !== "") {
              if ("Error" in data) {
                setLoading(false);
                setPage_Error(true);
                setError(data.Error);
              } else {
                setJson(data);
                let clm = changeIntoOriginalColmn(tabname); // target key will be maintableName
                if (clm) {
                  setColumn(data.formView[2].column, clm);
                } else setColumn(data.formView[2].column);
              }
            }
          });
      }
    }
  };
  const submitColumnbtn = () => {
    setIsClick(true)
    var jsson = json;
    var colm = changeIntoOriginalColmn(tabname);
    var colm2 = columnarray2;
    var sub = [];

    for (var i = 0; i < colm2.length; i++) {
      for (var j = 0; j < colm.length; j++) {
        if (colm2[i].key === colm[j].key && colm2[i].name === colm[j].name) {
          sub.push({
            name: colm[j].name,
            tabName: colm[j].tabName ?? "",
            type: colm[j].type,
            colId: colm[j].colId ?? "",
            key: colm[j].key ?? "",
          });
          break;
        }
        if (colm2[i].secondary) {
          sub.push({
            name: colm2[i].name,
            tabName: colm2[i].tabName,
            secondary: "" + colm2[i].secondary,
            path: colm2[i].path,
            type: colm2[i].type,
            colId: colm2[i].colId,
            key: colm2[i].key ?? "",
            label: colm2[i].label,
            tabPath: colm2[i].tabPath,
          });
          break;
        }
      }
    }
    jsson.formView[2].column = sub;
    jsson.formView[1].table.value = tabname;

    if (typename === "new" || typename === "record") {
      axios
        .post(loca + "/marketplace/set/view/column", jsson, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then((resp) => {
          var sysrecord = resp.data;

          if ("Error" in sysrecord) {
            toast(sysrecord.Error, {
              position: "top-center",
              theme: "colored",
              type: "error",
            });
          } else
            toast("Submit Successfully", {
              position: "top-center",
              theme: "colored",
              type: "success",
            });
        }).finally(()=>{
          setIsClick(false);
        });
    }
  };

  const changeIntoOriginalColmn = (key) => {
    const oldALmmCOl_mn = new Map(allcol_mn);
    let clm = allcol_mn.get(key);
    if (clm) {
      setCol_mn((prevCol_mn) => [...clm]);
      const newMap3 = new Map(refCols);
      newMap3.clear();
      setrefCols(newMap3);
      for (let [pp] of oldALmmCOl_mn) {
        if (pp != key) {
          oldALmmCOl_mn.delete(pp);
        }
      }
      setAllcol_mn(oldALmmCOl_mn);
      return clm;
    }
  };

  const geToBackRefCol = (targetKey) => {
    let found = false;
    let clm;
    //
    for (const [key] of refCols) {
      if (found) {
        refCols.delete(key);
      }
      if (key === targetKey) {
        found = true;
        clm = allcol_mn.get(key);
      }
    }

    if (refCols.size === 1) {
      refCols.clear();
      if (clm[clm.length - 1].label !== "--split-end--") {
        clm.push({
          label: "--split-start--",
          type: "ui",
          name: "--split-start--",
          check: false,
        });
        clm.push({
          label: "--split--",
          type: "ui",
          name: "--split--",
          check: false,
        });
        clm.push({
          label: "--split-end--",
          type: "ui",
          name: "--split-end--",
          check: false,
        });
      }
      setCol_mn([...clm]);
    } else {
      setCol_mn([...clm]);

      setisRefFieldSelected(false);
      setIsColArrSelected(false);
      setIsColArr2Selected(false);
    }
  };
  const selected = (index, clm) => {
    setIsColArrSelected(true);
    setIsColArr2Selected(false);

    setisRefFieldSelected(false);
    if (clm === "clm1") {
      if (col_mn[index]?.type === "reference") {
        setisRefFieldSelected(true);
        setselectedRefTab(col_mn[index]);
      }
      for (let i = 0; i < col_mn.length; i++) {
        if (i === index) {
          document
            .getElementById("colm1")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("colm1")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < columnarray2.length; i++) {
        document
          .getElementById("colm2")
          .children.item(i)
          .classList.remove("selected-row");
      }
    } else {
      setIsColArrSelected(false);
      setIsColArr2Selected(true);
      ind.current = index;
      for (let i = 0; i < columnarray2.length; i++) {
        if (i === index) {
          document
            .getElementById("colm2")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("colm2")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < col_mn.length; i++) {
        document
          .getElementById("colm1")
          .children.item(i)
          .classList.remove("selected-row");
      }
    }
  };

  const setColumnOrder = (pm) => {
    var clm = [];
    var col_odr = ind.current;
    var col_odr2 = 0;
    if (pm) {
      if (col_odr < columnarray2.length - 1) {
        col_odr2 = col_odr + 1;
        for (let i = 0; i < columnarray2.length; i++) {
          if (i !== col_odr2 && i !== col_odr) {
            clm.push(columnarray2[i]);
          } else if (i === col_odr2) {
            document
              .getElementById("colm2")
              .children.item(col_odr2)
              .classList.add("selected-row");
            let cjj = columnarray2[i];
            cjj.co = (col_odr + 1).toString();
            clm.push(cjj);
          } else if (i === col_odr) {
            document
              .getElementById("colm2")
              .children.item(col_odr)
              .classList.remove("selected-row");
            let cjj = columnarray2[i];
            cjj.co = (col_odr2 + 1).toString();
            clm.push(cjj);
          }
        }
        clm.sort((a, b) =>
          parseInt(a.co) > parseInt(b.co)
            ? 1
            : parseInt(a.co) < parseInt(b.co)
            ? -1
            : 0
        );
        ind.current = col_odr2;
        setColumnarray2([...clm]);
      }
    } else {
      if (col_odr > 0) {
        col_odr2 = col_odr - 1;
        for (let i = 0; i < columnarray2.length; i++) {
          if (i !== col_odr2 && i !== col_odr) {
            clm.push(columnarray2[i]);
          } else if (i === col_odr2) {
            document
              .getElementById("colm2")
              .children.item(col_odr2)
              .classList.add("selected-row");
            let cjj = columnarray2[i];
            cjj.co = (col_odr + 1).toString();
            clm.push(cjj);
          } else if (i === col_odr) {
            document
              .getElementById("colm2")
              .children.item(col_odr)
              .classList.remove("selected-row");
            let cjj = columnarray2[i];
            cjj.co = (col_odr2 + 1).toString();
            clm.push(cjj);
          }
        }
        clm.sort((a, b) =>
          parseInt(a.co) > parseInt(b.co)
            ? 1
            : parseInt(a.co) < parseInt(b.co)
            ? -1
            : 0
        );
        ind.current = col_odr2;
        setColumnarray2([...clm]);
      }
    }
  };

  const shiftRight = () => {
    let clm = col_mn;
    let clm2 = columnarray2;
    if (document.getElementsByClassName("selected-row").item(0)) {
      let lab = document
        .getElementsByClassName("selected-row")
        .item(0).innerText;
      for (let i = 0; i < col_mn.length; i++) {
        if (i === index.current) {
          if (clm[i].type !== "ui") {
            clm[i].check = true;
          }
          let n_co = clm2.length + 1;
          let st = "";
          let pt = "";
          let tbpth = "";
          let ct = 0;

          for (const [key] of refCols) {
            if (ct === 0) {
              st = st + refCols.get(key).one;
              pt = pt + refCols.get(key).path;
              tbpth = tbpth + refCols.get(key).tabPath;
            } else {
              st = st + ".";
              st = st + refCols.get(key).one;
              pt = pt + ".";
              pt = pt + refCols.get(key).path;
              tbpth = tbpth + ".";
              tbpth = tbpth + refCols.get(key).tabPath;
            }
            ct++;
          }
          if (ct > 0) {
            st = st + ".";
            pt = pt + ".";
          }

          let n = {
            label: st + lab,
            name: clm[i].name,
            co: n_co,
            tabName: clm[i].tabName,
            key: clm[i].key,
            path: pt + clm[i].name,
            secondary: !(st === ""),
            type: clm[i].type,
            colId: clm[i].colId,
            tabPath: tbpth,
          };
          clm2.push(n);
        }
      }
      setIsColArrSelected(false);
      setCol_mn([...clm]);
      setColumnarray2([...clm2]);
    }
  };

  const shiftLeft = () => {
    let clm = col_mn;
    let clm2 = columnarray2;
    let name = "";
    let obj = {};
    if (document.getElementsByClassName("selected-row").item(0)) {
      // let lab = document.getElementsByClassName("selected-row").item(0).innerText;
      for (let i = 0; i < columnarray2.length; i++) {
        if (i === ind.current) {
          name = clm2[i].name;
          obj = clm2[i];
          clm2.splice(i, 1);
          document
            .getElementsByClassName("selected-row")
            .item(0)
            .classList.remove("selected-row");
        }
      }
      if (allcol_mn.has(obj.key)) {
        clm = allcol_mn.get(obj.key);
      }
      for (let i = 0; i < col_mn.length; i++) {
        if (
          clm[i].key === obj.key &&
          clm[i].name === name &&
          !(
            clm[i].label === "--split--" ||
            clm[i].label === "--split-end--" ||
            clm[i].label === "--split-start--"
          )
        ) {
          clm[i].check = false;
        }
      }
      clm = col_mn;
      setIsColArrSelected(false);
      setIsColArr2Selected(false);
      setCol_mn([...clm]);
      setColumnarray2([...clm2]);
    }
  };
  return (
    <div
      className="Card"
      style={
        isMobile
          ? { height: "93vh", overflow: "auto", flexGrow: 1 }
          : { height: "95vh", overflow: "auto", flexGrow: 1 }
      }
    >
      <div style={{ position: "sticky", top: "0" }}>
        <Header
          tabName={"Form View SetUp"}
          isClick={isClick}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
      <div className="formViewBack" aria-readonly={getFormViewRecord}>
        {loading === true ? (
          <WorkInProgress />
        ) : (
          <div>
            {tabState === true && (
              <div
                className="row fr"
                style={{
                  marginLeft: "0rem",
                }}
              >
                {viewList.length === 0 && warning && (
                  <div>
                    <div className="alert alert-warning align-center p-0 ps-1">
                      <span className="fs-5">
                        You Don't have any custom view <br className="br-gap" />
                        Please create new custom View by presing button {}
                        <i className="fa-solid fa-caret-up fa-rotate-90"></i>
                      </span>
                      <span>
                        <button
                          onClick={() => {
                            navigation({
                              pathname: user + "/form",
                              search: createSearchParams({
                                tableName: "view_rule",
                                rty: "new",
                              }).toString(),
                            });
                          }}
                          className="btn btn-primary"
                        >
                          Create Custom View
                        </button>
                      </span>{" "}
                    </div>
                    <div></div>
                  </div>
                )}
                <div className="col-md-6 px-1">
                  <div className="text-start">
                    <span
                      className={mncolor ? "mndtryfals" : "mndtrytru"}
                      style={{ fontSize: "30px" }}
                    >
                      *
                    </span>
                    <span className="heading">Table</span>
                  </div>
                  <select
                    className="form-select pref_mar"
                    aria-label="Default"
                    onChange={(e) => formChangeTable(e.target.value)}
                    value={tabname}
                  >
                    <option value="none">None</option>
                    {tab_mn.map((obj, index) => (
                      <option key={index} value={obj.name}>
                        {obj.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className=" col-md-6 px-1">
                  <div className="text-start">
                    <span
                      className={mncolor ? "mndtryfals" : "mndtrytru"}
                      style={{ fontSize: "30px" }}
                    >
                      *
                    </span>
                    <span className="heading">View</span>
                  </div>
                  <select
                    id="one"
                    className="form-select pref_mar"
                    aria-label="Default"
                    onChange={(e) => formChangeType(e.target.value)}
                  >
                    <option value="none">None</option>
                    {viewList.length > 0 &&
                      viewList.map((obbj, ind) => (
                        <option key={ind} value={obbj.id}>
                          {obbj.label}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            )}
          </div>
        )}
        {flag && (
          <div className="row" style={{ marginTop: "2rem" }}>
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="row fr">
                <div className="my-2">
                  {Array.from(refCols).map(([key, value]) => (
                    <div key={key}>
                      <div
                        className="cursor-pointer current-field"
                        onClick={() => {
                          geToBackRefCol(key);
                        }}
                      >
                        {value.two}
                      </div>
                    </div>
                  ))}
                </div>
                <div id="colm1" className="col-md-5">
                  {col_mn.length > 0 &&
                    col_mn.map((obj, obj_i) => (
                      <div key={obj_i}>
                        {!obj.check && (
                          <div className="row fr">
                            <div
                              className="columnarray2 heading"
                              onClick={() => {
                                selected(obj_i, "clm1");
                                index.current = obj_i;
                              }}
                            >
                              <span
                                className={
                                  obj?.type === "reference"
                                    ? "greenLab"
                                    : "blackLab"
                                }
                              >
                                {obj.label}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
                <div className="col-md-1 up-down-dis">
                  {isRefFieldSelected && (
                    <div
                      onClick={() => {
                        callReferenceCol();
                      }}
                      className="text-center"
                    >
                      <i className="fa-solid fa-code-branch"></i>
                    </div>
                  )}
                  <div className="text-center">
                    <button
                      className="view_btn"
                      disabled={isColArr2Selected}
                      onClick={() => {
                        shiftRight();
                      }}
                    >
                      {">"}
                    </button>
                  </div>
                  <div className="text-center">
                    <button
                      className="view_btn"
                      disabled={isColArrSelected}
                      onClick={() => {
                        shiftLeft();
                      }}
                    >
                      {"<"}
                    </button>
                  </div>
                </div>
                <div id="colm2" className="col-md-5">
                  {columnarray2.map((obj, index) => (
                    <p
                      style={{ backgroundColor: "#ebebeb" }}
                      key={index}
                      className="columnarray2 heading my-1 px-1"
                      onClick={() => {
                        selected(index, "clm2");
                      }}
                      value={obj.label}
                    >
                      {obj.label}
                    </p>
                  ))}
                </div>
                <div className="col-md-1 up-down-dis">
                  <div className="text-center">
                    <button
                      className="up-down-view"
                      onClick={(e) => setColumnOrder(false)}
                    >
                      {">"}
                    </button>
                  </div>
                  <div className="text-center">
                    <button
                      className="up-down-view"
                      onClick={(e) => setColumnOrder(true)}
                    >
                      {"<"}
                    </button>
                  </div>
                </div>
              </div>
              <button
                style={{
                  fontSize: "15px",
                  // width: "20%",
                  borderRadius: "3px",
                }}
                type="button"
                className=" btnnn btn btn-primary"
                onClick={() => submitColumnbtn()}
              >
                Submit
              </button>
            </div>
            <div className="col-md-2"></div>
          </div>
        )}
      </div>
    </div>
  );
}
