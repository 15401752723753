import axios from "axios";
import React, { useState, useContext, useEffect, useRef } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { MarketContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
import "../css/ListComponent.css";
import NewFilterCompo from "./NewFilterCompo";
import { isMobile } from "react-device-detect";
import { Button, Modal } from "react-bootstrap";
import PrefCompo from "./PrefCompo";
import { toast } from "react-toastify";

import "../css/ListComponent.css";

const ModelList = ({
  previousFilter,
  previousTimeLine,
  columnid,
  onCancel,
  tableName,
  setRef,
  isVar,
  i_d,
  setshowModelList,
  isKV,
}) => {
  console.log(tableName);
  //  useContext hook
  const navigation = useNavigate();
  const { token, loca, user, appname, imageloca } = useContext(MarketContext);
  const [name, setName] = useState("");
  const [prefCall, setPrefCall] = useState([]);
  const [list, setList] = useState([]);
  const [iList, setIList] = useState(true);
  const [page_clicked, setPage_clicked] = useState(0);
  const [page_count, setPage_count] = useState(0);
  const [pageRecords, setPageRecords] = useState("");
  const [recordCount, setRecordCount] = useState();
  const [showlist, setShowlist] = useState(false);
  const [showbtn, setShowbtn] = useState(false);
  const [col_mn, setCol_mn] = useState([]);
  const [showPref, setShowPref] = useState(false);
  const [ref_fields, setRef_fields] = useState([]);
  const [filtarray, setFiltarray] = useState([]);
  const [filt, setFilt] = useState({
    co: "",
    cl: "",
    mc: "",
    an: "",
    ct: "",
    af: "",
    rf: { id: "", value: "" },
  });
  const [filString, setFilString] = useState("");
  const [button, setButton] = useState([]);
  const [mainrecord, setMainRecord] = useState({});
  const [record, setRecord] = useState([]);
  const [sortColumn, setSortColumn] = useState({ name: "", sort: false });
  const [listName, setListName] = useState(tableName);
  const [tableLabel, setTableLabel] = useState("");
  const [columnList, setColumnList] = useState([]);
  const [srch, setSrch] = useState("");
  const [srch_column, setSrch_column] = useState("name");
  const [showLoading, setShowLoading] = useState(true);
  const [filter, setFilter] = useState(previousFilter);
  const [timeLine, setTimeLine] = useState(previousTimeLine);

  const firstRenderForiListChange = useRef(false);

  const initialList = () => {
    // filtarray.push(filt);
    let f = [];
    let timeLineList = [];
    ref_fields.push("");
    ref_fields.push("=");
    ref_fields.push("starts with");
    timeLineList.push({ name: "", label: "None" });
    timeLineList.push({ name: "current24", label: "Current 24" });
    timeLineList.push({ name: "previous24", label: "Previous 24" });
    timeLineList.push({ name: "week", label: "Week" });
    timeLineList.push({ name: "last14days", label: "Last 14 Days" });
    timeLineList.push({ name: "last30", label: "Last 30" });
    timeLineList.push({ name: "last6month", label: "Last 6 Month" });
    timeLineList.push({ name: "last1year", label: "Last 1 Year" });
    f.push(filt);
    if (
      filter.toString() === "" ||
      filter.toString() === "undefined" ||
      filter.toString() === undefined ||
      filter.toString() === "null" ||
      filter.toString() === null
    ) {
      var farray = [];
      farray.push(filt);
      setFilter([...farray]);
      setFiltarray([...farray]);
      setIList(!iList);
    } else {
      var farray = [];
      farray = filter.filter;
      setFilter([...farray]);
      setFiltarray([...f]);
      setIList(!iList);
    }
  };
  const handleCloseRefresh = () => {
    handleClose();
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","name":"marketplace"}}';
    tablere += ',{"table":{"id":"","name":"' + name + '"}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
    tablere += ',{"filter":' + JSON.stringify(filtarray) + "}";
    tablere += ',{"timeLine":"' + timeLine + '"}]}';
    getList(tablere, true);
    // setList(tablere);
  };
  const setlist = (tablere) => {
    axios
      .post(loca + "/marketplace/get/multiple/record", tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          const listrecord = resp.data;
          var columnarry = [];
          var hd = [];
          var va_ll = [];
          if ("Error" in listrecord) {
          } else {
            var scnd = listrecord.formRecordList[2];
            if ("Error" in scnd) {
              if (scnd.Error === "No record found.") {
                setList([]);
              }
            } else {
              setTableLabel(listrecord.formRecordList[1].table.label);

              var pageClicked1 = parseInt(
                listrecord.formRecordList[3].page.page_clicked
              );
              var pageCount1 = parseInt(
                listrecord.formRecordList[3].page.page_count
              );
              var page_records1 =
                listrecord.formRecordList[3].page.page_records;
              var record_count1 = parseInt(
                listrecord.formRecordList[3].page.record_count
              );
              var fltarr = listrecord.formRecordList[5].filter;
              var tmln = listrecord.formRecordList[6].timeLine;
              var filterString = "";
              var leng = fltarr.length;
              for (var f = 0; f < fltarr.length; f++) {
                if (leng === 1 && fltarr[f].cl === "") {
                  break;
                } else {
                  if (f > 0) {
                    filterString += fltarr[f].af + " ";
                  }
                  filterString += fltarr[f].cl + " ";
                  filterString += fltarr[f].mc + " ";
                  if (fltarr[f].ct === "reference") {
                    filterString += fltarr[f].rf.value + " ";
                  } else {
                    filterString += fltarr[f].an + " ";
                  }
                }
              }
              for (
                var i = 0;
                i < listrecord.formRecordList[2].records.length;
                i++
              ) {
                var in_vl = [];
                var record_id;
                for (
                  var j = 0;
                  j < listrecord.formRecordList[2].records[i].record.length;
                  j++
                ) {
                  if (j === 0) {
                    record_id =
                      listrecord.formRecordList[2].records[i].record[j].value;
                  } else if (j === 1) {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value.value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                        name: listrecord.formRecordList[2].records[i].record[j]
                          .value.name,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                        name: listrecord.formRecordList[2].records[i].record[j]
                          .name,
                      });
                    }
                  } else {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                        name: listrecord.formRecordList[2].records[i].record[j]
                          .name,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                        name: listrecord.formRecordList[2].records[i].record[j]
                          .name,
                      });
                    }
                  }
                }
                in_vl.splice(0, 0, { ref: false });
                va_ll.push({ colr: in_vl });
              }
              let col_list = [];
              for (
                var c = 0;
                c < listrecord.formRecordList[9].column.length;
                c++
              ) {
                if (listrecord.formRecordList[9].column[c].type === "String") {
                  col_list.push({
                    label: listrecord.formRecordList[9].column[c].label,
                    name: listrecord.formRecordList[9].column[c].name,
                    type: listrecord.formRecordList[9].column[c].type,
                  });
                }
              }
              for (
                var p = 0;
                p < listrecord.formRecordList[10].preference.length;
                p++
              ) {
                hd.push({
                  label: listrecord.formRecordList[10].preference[p].label,
                  name: listrecord.formRecordList[10].preference[p].name,
                  type: listrecord.formRecordList[10].preference[p].type,
                });
              }
              hd.splice(0, 0, { ref: false });
              columnarry.push({ heading: hd, rcd: va_ll });
              let column = listrecord.formRecordList[9].column;
              if ("Error" in column) {
                toast(column.Error, {
                  type: "error",
                  position: "bottom-center",
                });
              } else {
                // let colToShow = [];
                // for (let i = 0; i < column.length; i++) {
                //   if (
                //     column[i].name !== "group_properties" &&
                //     column[i].name !== "properties"
                //   ) {
                //     colToShow.push(column[i]);
                //   }
                // }
                var cla = JSON.parse(
                  JSON.stringify(listrecord.formRecordList[9].column)
                );
                cla.unshift({ label: "None", name: "none" });
                setList(columnarry);
                setPageRecords(page_records1);
                setRecordCount(record_count1);
                setCol_mn(cla);
                setPrefCall(column);
                setFilter(fltarr);
                setFilString(filterString);
                setButton(listrecord.formRecordList[11].button);
                setColumnList(col_list);
              }
            }
          }
        },
        (error) => {}
      );
  };

  const getList = (tablere,isPref) => {
    // let token = await AsyncStorage.getItem("token");
    if (isKV) {
      axios
        .post(loca + "/marketplace/get/grpkeyvalues", tablere.toString(), {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then(
          (resp) => {
            const listrecord = resp.data;
            var columnarry = [];
            var hd = [];
            var va_ll = [];
            if ("Error" in listrecord) {
              setshowModelList(false);
              toast(listrecord.Error, {
                position: "bottom-center",
                theme: "colored",
                type: "error",
              });
            } else {
              var scnd = listrecord.formRecordList[2];
              setListName(listrecord.formRecordList[1].table.name);
              if ("Error" in scnd) {
                if (scnd.Error === "No record found.") {
                  // setState({ list: [] });
                  setList([]);
                }
              } else {
                var page_clicked1 = parseInt(
                  listrecord.formRecordList[3].page.page_clicked
                );
                var page_count1 = parseInt(
                  listrecord.formRecordList[3].page.page_count
                );
                var page_records1 =
                  listrecord.formRecordList[3].page.page_records;
                var record_count1 = parseInt(
                  listrecord.formRecordList[3].page.record_count
                );

                var flt = listrecord.formRecordList[5];
                var fltarr = listrecord.formRecordList[5].filter;
                var tmln = listrecord.formRecordList[6].timeLine;
                var filterString = "";
                var leng = fltarr.length;

                for (var f = 0; f < fltarr.length; f++) {
                  if (leng === 1 && fltarr[f].co === "") {
                    break;
                  } else {
                    if (f > 0) {
                      filterString += fltarr[f].af + " ";
                    }
                    filterString += fltarr[f].cl + " ";
                    filterString += fltarr[f].mc + " ";
                    if (fltarr[f].ct === "reference") {
                      filterString += fltarr[f].rf.value + " ";
                    } else {
                      filterString += fltarr[f].an + " ";
                    }
                  }
                }

                for (
                  var i = 0;
                  i < listrecord.formRecordList[2].records.length;
                  i++
                ) {
                  var in_vl = [];
                  var record_id;
                  var ref_value;
                  for (
                    var j = 0;
                    j < listrecord.formRecordList[2].records[i].record.length;
                    j++
                  ) {
                    if (j === 0) {
                      record_id =
                        listrecord.formRecordList[2].records[i].record[j].value;
                      if (
                        listrecord.formRecordList[2].records[i].record[j]
                          .refDisplay.type === "reference"
                      ) {
                        ref_value =
                          listrecord.formRecordList[2].records[i].record[j]
                            .refDisplay.value.value;
                      } else {
                        ref_value =
                          listrecord.formRecordList[2].records[i].record[j]
                            .refDisplay.value;
                      }
                    } else if (j === 1) {
                      if (
                        listrecord.formRecordList[2].records[i].record[j]
                          .type === "reference"
                      ) {
                        in_vl.push({
                          value:
                            listrecord.formRecordList[2].records[i].record[j]
                              .value,
                          type: listrecord.formRecordList[2].records[i].record[
                            j
                          ].type,
                          firstrecord: true,
                          table: listrecord.formRecordList[1].table.name,
                          r_id: record_id,
                          ref_value: ref_value,
                        });
                      } else {
                        in_vl.push({
                          value:
                            listrecord.formRecordList[2].records[i].record[j]
                              .value,
                          type: listrecord.formRecordList[2].records[i].record[
                            j
                          ].type,
                          firstrecord: true,
                          table: listrecord.formRecordList[1].table.name,
                          r_id: record_id,
                          ref_value: ref_value,
                        });
                      }
                    } else {
                      if (
                        listrecord.formRecordList[2].records[i].record[j]
                          .type === "reference"
                      ) {
                        //  console.log(
                        //     JSON.stringify(
                        //       listrecord.formRecordList[2].records[i].record[j]
                        //     ))
                        in_vl.push({
                          value:
                            listrecord.formRecordList[2].records[i].record[j]
                              .value,
                          type: listrecord.formRecordList[2].records[i].record[
                            j
                          ].type,
                          firstrecord: false,
                          table: listrecord.formRecordList[1].table.name,
                          r_id: record_id,
                        });
                      } else {
                        in_vl.push({
                          value:
                            listrecord.formRecordList[2].records[i].record[j]
                              .value,
                          type: listrecord.formRecordList[2].records[i].record[
                            j
                          ].type,
                          firstrecord: false,
                          table: listrecord.formRecordList[1].table.name,
                          r_id: record_id,
                        });
                      }
                    }
                  }
                  va_ll.push({ colr: in_vl });
                }
                let col_list = [];
                for (
                  var c = 0;
                  c < listrecord.formRecordList[9].column.length;
                  c++
                ) {
                  if (
                    listrecord.formRecordList[9].column[c].type === "String"
                  ) {
                    col_list.push({
                      label: listrecord.formRecordList[9].column[c].label,
                      name: listrecord.formRecordList[9].column[c].name,
                      type: listrecord.formRecordList[9].column[c].type,
                    });
                  }
                }
                for (
                  var p = 0;
                  p < listrecord.formRecordList[10].preference.length;
                  p++
                ) {
                  hd.push({
                    label: listrecord.formRecordList[10].preference[p].label,
                    name: listrecord.formRecordList[10].preference[p].name,
                    type: listrecord.formRecordList[10].preference[p].type,
                  });
                }
                columnarry.push({ heading: hd, rcd: va_ll });
                let column = listrecord.formRecordList[9].column;
                if ("Error" in column) {
                  toast(column.Error, {
                    type: "error",
                    position: "bottom-center",
                  });
                } else {
                  var cla = listrecord.formRecordList[9].column;
                  cla.unshift({ label: "None", name: "none" });

                  setFiltarray(fltarr);

                  setList(columnarry);

                  setName(listrecord.formRecordList[1].table.name);

                  setPrefCall(column);
                  setPage_clicked(page_clicked1);
                  setPage_count(page_count1);
                  setPageRecords(page_records1);
                  setRecordCount(record_count1);
                  setCol_mn(cla);
                  setColumnList(col_list);
                  setFilter(fltarr);
                  setTableLabel(listrecord.formRecordList[1].table.label);
                  setButton(listrecord.formRecordList[11].button);
                  setFilString(filterString);
                  setShowLoading(false);
                }
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      let url = "";
      if (isVar) {
        url = loca + `/get/multiplerecord/ref`;
      }else if(isPref){
        url = loca + `/marketplace/get/multiple/record`;
      } else {
        url = loca + `/marketplace/get/multiplerecord/col`;
      }
      // functions for calling server

      axios
        .post(url, tablere.toString(), {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then(
          (resp) => {
            const listrecord = resp.data;
            var columnarry = [];
            var hd = [];
            var va_ll = [];
            if ("Error" in listrecord) {
              setshowModelList(false);
              toast(listrecord.Error, {
                position: "bottom-center",
                theme: "colored",
                type: "error",
              });
            } else {
              var scnd = listrecord.formRecordList[2];
              setListName(listrecord.formRecordList[1].table.name);
              if ("Error" in scnd) {
                if (scnd.Error === "No record found.") {
                  // setState({ list: [] });
                  setList([]);
                }
              } else {
                var page_clicked1 = parseInt(
                  listrecord.formRecordList[3].page.page_clicked
                );
                var page_count1 = parseInt(
                  listrecord.formRecordList[3].page.page_count
                );
                var page_records1 =
                  listrecord.formRecordList[3].page.page_records;
                var record_count1 = parseInt(
                  listrecord.formRecordList[3].page.record_count
                );

                var flt = listrecord.formRecordList[5];
                var fltarr = listrecord.formRecordList[5].filter;
                var tmln = listrecord.formRecordList[6].timeLine;
                var filterString = "";
                var leng = fltarr.length;

                for (var f = 0; f < fltarr.length; f++) {
                  if (leng === 1 && fltarr[f].co === "") {
                    break;
                  } else {
                    if (f > 0) {
                      filterString += fltarr[f].af + " ";
                    }
                    filterString += fltarr[f].cl + " ";
                    filterString += fltarr[f].mc + " ";
                    if (fltarr[f].ct === "reference") {
                      filterString += fltarr[f].rf.value + " ";
                    } else {
                      filterString += fltarr[f].an + " ";
                    }
                  }
                }

                for (
                  var i = 0;
                  i < listrecord.formRecordList[2].records.length;
                  i++
                ) {
                  var in_vl = [];
                  var record_id;
                  var ref_value;
                  for (
                    var j = 0;
                    j < listrecord.formRecordList[2].records[i].record.length;
                    j++
                  ) {
                    if (j === 0) {
                      record_id =
                        listrecord.formRecordList[2].records[i].record[j].value;
                      if (
                        listrecord.formRecordList[2].records[i].record[j]
                          .refDisplay.type === "reference"
                      ) {
                        ref_value =
                          listrecord.formRecordList[2].records[i].record[j]
                            .refDisplay.value.value;
                      } else {
                        ref_value =
                          listrecord.formRecordList[2].records[i].record[j]
                            .refDisplay.value;
                      }
                    } else if (j === 1) {
                      if (
                        listrecord.formRecordList[2].records[i].record[j]
                          .type === "reference"
                      ) {
                        in_vl.push({
                          value:
                            listrecord.formRecordList[2].records[i].record[j]
                              .value,
                          type: listrecord.formRecordList[2].records[i].record[
                            j
                          ].type,
                          firstrecord: true,
                          table: listrecord.formRecordList[1].table.name,
                          r_id: record_id,
                          ref_value: ref_value,
                        });
                      } else {
                        in_vl.push({
                          value:
                            listrecord.formRecordList[2].records[i].record[j]
                              .value,
                          type: listrecord.formRecordList[2].records[i].record[
                            j
                          ].type,
                          firstrecord: true,
                          table: listrecord.formRecordList[1].table.name,
                          r_id: record_id,
                          ref_value: ref_value,
                        });
                      }
                    } else {
                      if (
                        listrecord.formRecordList[2].records[i].record[j]
                          .type === "reference"
                      ) {
                        //  console.log(
                        //     JSON.stringify(
                        //       listrecord.formRecordList[2].records[i].record[j]
                        //     ))
                        in_vl.push({
                          value:
                            listrecord.formRecordList[2].records[i].record[j]
                              .value,
                          type: listrecord.formRecordList[2].records[i].record[
                            j
                          ].type,
                          firstrecord: false,
                          table: listrecord.formRecordList[1].table.name,
                          r_id: record_id,
                        });
                      } else {
                        in_vl.push({
                          value:
                            listrecord.formRecordList[2].records[i].record[j]
                              .value,
                          type: listrecord.formRecordList[2].records[i].record[
                            j
                          ].type,
                          firstrecord: false,
                          table: listrecord.formRecordList[1].table.name,
                          r_id: record_id,
                        });
                      }
                    }
                  }
                  va_ll.push({ colr: in_vl });
                }
                let col_list = [];
                for (
                  var c = 0;
                  c < listrecord.formRecordList[9].column.length;
                  c++
                ) {
                  if (
                    listrecord.formRecordList[9].column[c].type === "String"
                  ) {
                    col_list.push({
                      label: listrecord.formRecordList[9].column[c].label,
                      name: listrecord.formRecordList[9].column[c].name,
                      type: listrecord.formRecordList[9].column[c].type,
                    });
                  }
                }
                for (
                  var p = 0;
                  p < listrecord.formRecordList[10].preference.length;
                  p++
                ) {
                  hd.push({
                    label: listrecord.formRecordList[10].preference[p].label,
                    name: listrecord.formRecordList[10].preference[p].name,
                    type: listrecord.formRecordList[10].preference[p].type,
                  });
                }
                columnarry.push({ heading: hd, rcd: va_ll });
                let column = listrecord.formRecordList[9].column;
                if ("Error" in column) {
                  toast(column.Error, {
                    type: "error",
                    position: "bottom-center",
                  });
                } else {
                  var cla = listrecord.formRecordList[9].column;
                  cla.unshift({ label: "None", name: "none" });
                  setFiltarray(fltarr);
                  setList(columnarry);
                  setName(listrecord.formRecordList[1].table.name);
                  setPrefCall(column);
                  setPage_clicked(page_clicked1);
                  setPage_count(page_count1);
                  setPageRecords(page_records1);
                  setRecordCount(record_count1);
                  setCol_mn(cla);
                  setColumnList(col_list);
                  setFilter(fltarr);
                  setTableLabel(listrecord.formRecordList[1].table.label);
                  setButton(listrecord.formRecordList[11].button);
                  // console.log("filterString" + filterString);
                  setFilString(filterString);
                  setShowLoading(false);
                }
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  const handleShow = () => {
    setShowPref(true);
  };

  const handleClose = () => {
    setShowPref(false);
  };

  const changeSearch = (val) => {
    if (val === "") {
      // this.setState({ srch: "" });
      setSrch("");
      filterClear();
    } else {
      // this.setState({ srch: val });
      setSrch(val);
    }
  };

  const callSearchbtn = () => {
    // var fld = this.state.srch_column;
    // var val = this.state.srch;
    // var col = this.state.columnList;
    var type = "";
    var label = "";
    for (var c = 0; c < columnList.length; c++) {
      if (srch_column === columnList[c].name) {
        type = columnList[c].type;
        label = columnList[c].label;
      }
    }
    let filte = [
      {
        ct: type,
        af: "",
        mc: "=",
        cl: label,
        co: srch_column,
        an: srch,
        rf: { id: "", value: "" },
      },
    ];
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","name":"marketplace"}}';
    // fs += ',{"table":{"id":"' + columnid + '","name":""}}';
    fs +=
      ',{"table":{"id":"' +
      columnid +
      '","name":"' +
      tableName.name +
      '","label":""}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id","init":"false"}}';
    fs += ',{"filter":' + JSON.stringify(filte) + "}";
    fs += ',{"timeLine":"' + timeLine + '"}]}';
    setFiltarray([...filte]);
    getList(fs);
  };

  const searchColumn = (e) => {
    // this.setState({ srch_column: e.target.value });
    setSrch_column(e.target.value);
  };

  const filterSubmit = () => {
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","name":"marketplace"}}';
    fs += ',{"table":{"id":"' + columnid + '","name":""}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id" ,"init":"false"}}';
    fs += ',{"filter":' + JSON.stringify(filtarray) + "}";
    fs += ',{"timeLine":"' + timeLine + '"}]}';
    getList(fs);
  };

  const filterClear = () => {
    var fltarray = [];
    let flt = {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
    };
    fltarray.push(flt);
    setFiltarray(fltarray);
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"","name":"' + name + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(fltarray) + "}";
    pp += ',{"timeLine":"' + timeLine + '"}]}';

    setlist(pp);
    // setClearButtonLoading(true);
    // var fltarray = [];
    // fltarray.push(filt);
    // // setState({ filtarray: fltarray });
    // setFiltarray(fltarray);
    // setFiltArrayChange(!filtArrayChange);
  };

  // sorting function

  const showupdownbtn = (hd) => {
    var sarray = sortColumn;
    var clmn = "";
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"' + columnid + '","name":""}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';

    if (sarray.name === hd) {
      sarray.sort = !sarray.sort;
      for (var l = 0; l < list.length; l++) {
        for (var ll = 0; ll < list[l].heading.length; ll++) {
          if (list[l].heading[ll].label === hd) {
            clmn = list[l].heading[ll].name;
            break;
          }
        }
      }

      if (sarray.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
    } else {
      sarray.name = hd;
      sarray.sort = true;
      for (var li = 0; li < list.length; li++) {
        for (var lll = 0; lll < list[li].heading.length; lll++) {
          if (list[li].heading[lll].label === hd) {
            clmn = list[li].heading[lll].name;
            break;
          }
        }
      }
      if (sarray.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
    }
    // setState({ sortColumn: sarray });
    setSortColumn(sarray);
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeLine + '"}]}';
    getList(pp);
  };

  const setSort = () => {
    var clmn = "";
    var pp = "";
    var srt = sortColumn.name;
    if (srt !== "") {
      for (var l = 0; l < list.length; l++) {
        for (var ll = 0; ll < list[l].heading.length; ll++) {
          if (list[l].heading[ll].label === sortColumn.name) {
            clmn = list[l].heading[ll].name;
            break;
          }
        }
      }
      if (sortColumn.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
      return pp;
    } else {
      pp +=
        ',{"sort":{"asc":"true","column":"' +
        list[0].heading[0].name +
        '","init":"false"}}';
    }
    return pp;
  };

  // pagination functions

  const leftReadOnly = () => {
    if (page_clicked === 1) {
      return true;
    } else {
      return false;
    }
  };

  const rightReadOnly = () => {
    if (page_clicked === page_count) {
      return true;
    } else {
      return false;
    }
  };

  const previousPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"' + columnid + '","name":""}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (page_clicked - 1) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeLine + '"}]}';

    getList(pp);
  };

  const nextPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"' + columnid + '","name":""}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (page_clicked + 1) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeLine + '"}]}';

    getList(pp);
  };

  const firstPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"' + columnid + '","name":""}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeLine + '"}]}';

    getList(pp);
  };

  const lastPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"marketplace"}}';
    pp += ',{"table":{"id":"' + columnid + '","name":""}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      page_count +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeLine + '"}]}';

    getList(pp);
  };

  const filterItem = () => {
    // this.setState({ showlist: !showlist });
    // this.setState({ showbtn: !showbtn });
    setShowlist(!showlist);
    setShowbtn(!showbtn);
  };

  const callform = (nam, r_id, refDisplay, rowIndex, oj, refTable) => {
    let properties;
    if (nam === "first") {
      var colr = list[0].rcd[rowIndex].colr;
      if (isKV) {
        for (let i = 0; i < colr.length; i++) {
          if (colr[i].type === "group_key_value") {
            properties = colr[i].value;
            break;
          }
        }
      }
      setRef(r_id, refDisplay, properties, refTable);
    } else if (nam === "new") {
      navigation({
        pathname: user + "/form",
        search: createSearchParams({
          // state: { rty: "new", tableName: tab, rid: 0 },
          rty: "new",
          tableName: r_id,
          rid: 0,
        }).toString(),
      });
      // navigation("/form", { state: { rty: "new", tableName: r_id, rid: 0 } });
      // showFormCompo(tab, 0, "new");
    }
  };

  const getChoiceRcd = async (col_id) => {
    let chk = [];
    let ck = await axios
      .get(loca + "/marketplace/get/choice/" + col_id, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          let chk = res.choiceRecords;
          return chk;
          // console.log(rcd);
        },
        (error) => {
          console.log(error);
        }
      );
    return ck;
  };

  const callfilter = async (farray, in_index, col_id) => {
    if (col_id !== -1) {
      for (let i = 0; i < list.length; i++) {
        //   console.log(index+" index "+i+" rcddddddd "+JSON.stringify(rcd[i]));
        if (
          farray[in_index].ct === "choice" &&
          farray[in_index].ch.length === 0
        ) {
          let chc = await getChoiceRcd(col_id).then((res) => {
            if (res.length > 0) {
              farray[in_index].ch = res;
              farray[in_index].an = res[0].name;
            } else {
              farray[in_index].ch = [];
            }
          });
          //  console.log(chcRcd.current);
        }
      }
    }
    setFiltarray([...farray]);
  };

  const calltimeline = (timeline) => {
    setTimeLine(timeline);
  };

  useEffect(() => {
    if (firstRenderForiListChange.current) {
      var tablere = '{"formRecordList":[';
      tablere += '{"application":{"id":"","name":"marketplace"}}';
      tablere += ',{"table":{"id":"' + columnid + '","name":""}}';
      tablere += ',{"records":[]}';
      tablere +=
        ',{"page":{"record_count":"0","page_count":"1",' +
        '"page_clicked":"1","page_records":"0"}}';
      tablere += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
      tablere += ',{"filter":' + JSON.stringify(filter) + "}";
      tablere += ',{"timeLine":"' + timeLine + '"}]}';

      // console.log("checkssssssssssssssssssssssssssssss" + tablere);
      getList(tablere);
      // firstRenderForiListChange.current = false;
    } else {
      // if (filter != "null") {
      firstRenderForiListChange.current = true;
      // }
      initialList();
    }
    // console.log("initialfilter :" + firstRenderForiListChange.current);
  }, [iList]);

  const callbtn = (nam) => {
    var btn = button;
    var mnrecord = mainrecord;
    for (var ij = 0; ij < btn.length; ij++) {
      if (btn[ij].value === nam) {
        var btntype = btn[ij].buttonWebType;
        var nextPage = btn[ij].returnWebLocation;
        var callType = btn[ij].call_type;
        if (callType === "selection") {
          var returnType = btn[ij].recordReturnType;
          navigation("/role-selection", {
            state: { i_d: i_d, rt: returnType },
          });
        } else if (nextPage === "NextPage") {
          onCancel(false);
          callform("new", listName, 0);
        } else {
          mnrecord.formRecord[2].record = record;
          axios
            .post(loca + btn[ij].webUrl, mnrecord, {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + token,
                "market-application": appname,
              },
            })
            .then((resp) => {
              const rcd = resp.data;
              console.log(rcd);
            });
        }
      }
    }
  };

  return (
    <div className="pagesetup">
      {showLoading === true ? (
        <WorkInProgress></WorkInProgress>
      ) : (
        <div>
          {isMobile === true ? (
            <div className="row bck bck-rel">
              <div className="col-lg mid">
                <span className="obj_head m-0">{tableLabel}</span>
              </div>
              <div className="col-lg filter-ic">
                {showlist === false && (
                  <select
                    className="form-select namelist-mob"
                    aria-label="Default"
                    value={srch_column}
                    onChange={(e) => searchColumn(e)}
                  >
                    {columnList.map((obj2, index) => (
                      <option key={index} value={obj2.name}>
                        {obj2.label}
                      </option>
                    ))}
                  </select>
                )}

                {showlist === false && (
                  <input
                    className="srch-mob form-control"
                    type="search"
                    aria-label="Search"
                    value={srch}
                    onChange={(e) => changeSearch(e.target.value)}
                  ></input>
                )}

                {showlist === false && (
                  <input
                    className="csm_btn csm_btn_pri col-md-2 sub-btn"
                    type="button"
                    value="Search"
                    onClick={() => callSearchbtn()}
                  ></input>
                )}
              </div>
              <div className="col-lg martop10 disfl filter-ic">
                <i
                  className="fa fa-filter vlpointer pdtop5"
                  onClick={filterItem}
                ></i>
                <i
                  className="fa fa-cog vlpointer cog_pd pdtop5"
                  onClick={handleShow}
                ></i>
                <div className="">
                  <span style={{ marginLeft: "0.5em" }}>
                    <input
                      className=" mybt"
                      type="button"
                      value="<<"
                      onClick={firstPage}
                      disabled={leftReadOnly()}
                    />
                    <input
                      className=" mybt"
                      type="button"
                      value="<"
                      onClick={previousPage}
                      disabled={leftReadOnly()}
                    />
                  </span>
                  <span>
                    <input
                      className="in-put"
                      type="text"
                      readOnly={true}
                      value={pageRecords}
                    ></input>
                  </span>
                  <span className="text-uppercase spfont"> of </span>
                  <span className="rcd_count spfont">{recordCount}</span>
                  <span>
                    <input
                      className=" mybt"
                      type="button"
                      value=">"
                      onClick={nextPage}
                      disabled={rightReadOnly()}
                    />
                  </span>
                  <span>
                    <input
                      className=" mybt"
                      type="button"
                      value=">>"
                      disabled={rightReadOnly()}
                      onClick={lastPage}
                    />
                  </span>
                </div>
              </div>
              <div className="col-lg martop10">
                {button.length > 0 &&
                  button.map((obj, oo_i) => (
                    <input
                      type="button"
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      key={oo_i}
                      onClick={(e) => callbtn(obj.value)}
                    >
                      {obj.name}
                    </input>
                  ))}
              </div>
              <div className="col-lg martop10">
                {showlist === true && (
                  <input
                    className="csm_btn csm_btn_pri col-md-2 sub-btn"
                    type="button"
                    value="Run"
                    onClick={filterSubmit}
                  ></input>
                )}

                {showlist === true && (
                  <input
                    className="csm_btn csm_btn_pri col-md-2 sub-btn"
                    type="button"
                    value="Clear"
                    onClick={filterClear}
                  ></input>
                )}
              </div>
            </div>
          ) : (
            <div className="row bck bck-rel">
              <div className="col-lg filter-ic p-0">
                <i className="fa fa-filter vlpointer" onClick={filterItem}></i>

                <i
                  className="fa fa-cog vlpointer cog_pd"
                  onClick={handleShow}
                ></i>

                {showlist === true && (
                  <input
                    className="btn btn-primary me-2 btn-sm"
                    type="button"
                    value="Run"
                    onClick={filterSubmit}
                  ></input>
                )}

                {showlist === true && (
                  <input
                    className="btn btn-primary btn-sm"
                    type="button"
                    value="Clear"
                    onClick={filterClear}
                  ></input>
                )}

                {showlist === false && (
                  <select
                    style={{
                      lineHeight: "0.8em",
                      width: "10em",
                      display: "inline",
                    }}
                    className="form-select"
                    aria-label="Default"
                    value={srch_column}
                    onChange={(e) => searchColumn(e)}
                  >
                    {columnList.map((obj2, index) => (
                      <option key={index} value={obj2.name}>
                        {obj2.label}
                      </option>
                    ))}
                  </select>
                )}

                {showlist === false && (
                  <input
                    className="srch form-control"
                    type="search"
                    aria-label="Search"
                    value={srch}
                    onChange={(e) => changeSearch(e.target.value)}
                  ></input>
                )}

                {showlist === false && (
                  <button
                    className=" insrtbtn2 btn btn btn-primary btn-sm"
                    type="button"
                    onClick={() => callSearchbtn()}
                    style={{
                      height: "1.73rem",
                    }}
                  >
                    Search
                  </button>
                )}
              </div>
              <div className="col-lg mid">
                <p className="obj_head m-0">{tableLabel}</p>
              </div>
              <div className="col-lg bt_padd ">
                {button.length > 0 &&
                  button.map((obj, oo_i) => (
                    <div key={oo_i}>
                      {obj.show_type === "modelList" && (
                        <button
                          className=" insrtbtn2 btn btn btn-primary btn-sm"
                          style={{
                            height: "1.73rem",
                          }}
                          key={oo_i}
                          onClick={(e) => callbtn(obj.value)}
                        >
                          {obj.name}
                        </button>
                      )}
                    </div>
                  ))}
                <div className="d-flex">
                  <input
                    className=" mybt"
                    type="button"
                    value="<<"
                    onClick={firstPage}
                    disabled={leftReadOnly()}
                  />
                  <input
                    className=" mybt"
                    type="button"
                    value="<"
                    onClick={previousPage}
                    disabled={leftReadOnly()}
                  />

                  <span className="in-put">{pageRecords}</span>
                  <span className="text-uppercase spfont"> of </span>

                  <span className="rcd_count spfont">{recordCount}</span>
                  <input
                    className=" mybt"
                    type="button"
                    value=">"
                    onClick={nextPage}
                    disabled={rightReadOnly()}
                  />
                  <input
                    className=" mybt"
                    type="button"
                    value=">>"
                    disabled={rightReadOnly()}
                    onClick={lastPage}
                  />
                </div>
              </div>
            </div>
          )}
          {showlist === false && filString !== "" && <div>{filString}</div>}
          {showlist === true && (
            <NewFilterCompo
              showlist={showlist}
              col_mn={col_mn}
              call_fil={(filtarray, in_index, col_id) => {
                callfilter(filtarray, in_index, col_id);
              }}
              filtarray={filtarray}
              setFiltArray={setFiltarray}
              timeline={timeLine}
              setTimeLine={(timeline) => calltimeline(timeline)}
              setScript={(script) => {}}
              isScript={false}
              tableName={listName}
            />
          )}
{console.log(list)}
          {list.length === 0 && <div>No Record Found</div>}
          {list.map((lstobj, l_i) => (
            <div
              // className="heading_top table_set over"
              className="heading_top table_set overflow-auto"
              style={{ height: "59vh", position: "relative" }}
              // style={{ height: "600px" }}
              key={l_i}
            >
              <table
                className="table table-bordered table-striped table-hover p-1"
                // style={{ overflow: "auto" }}
                style={{ borderTop: "1px solid white" }}
              >
                <thead
                  className="table-dark"
                  style={{ position: "sticky", top: "1px" }}
                >
                  <tr className="obj_name">
                    {lstobj.heading.map(
                      (obj, o_i) =>
                        obj.type !== "group_key_value" &&
                        obj.type !== "key_value" &&
                        obj.type !== "password" && (
                          <th
                            className="vlpointer  px-2 py-1"
                            onClick={(e) => showupdownbtn(obj.label)}
                            key={o_i}
                          >
                            {obj.label}
                            {sortColumn.name === obj.label &&
                              sortColumn.sort === true && (
                                <i className=" icpadding fa fa-arrow-up"></i>
                              )}
                            {sortColumn.name === obj.label &&
                              sortColumn.sort === false && (
                                <i className=" icpadding fa fa-arrow-down"></i>
                              )}
                          </th>
                        )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {lstobj.rcd.map((objj, ob_i) => (
                    <tr className="obj_value" key={ob_i}>
                      {objj.colr.map(
                        (objr, or_i) =>
                          objr.type !== "group_key_value" &&
                          objr.type !== "key_value" &&
                          objr.type !== "password" && (
                            <td
                              className={
                                objr.firstrecord === true
                                  ? "val_pad val_under vlpointer"
                                  : "val_pad"
                              }
                              onClick={
                                objr.firstrecord === true
                                  ? (e) => {
                                      e.preventDefault();

                                      callform(
                                        "first",
                                        objr.r_id,
                                        objr.ref_value,
                                        ob_i,
                                        or_i,
                                        objr.table
                                      );
                                    }
                                  : (e) =>
                                      callform(
                                        "second",
                                        "",
                                        "",
                                        "",
                                        "",
                                        objr.table
                                      )
                              }
                              key={or_i}
                            >
                              {objr.type === "image_upload" ? (
                                <img
                                  style={{
                                    height: "90px",
                                    width: "100%",
                                    objectFit: "contain",
                                  }}
                                  src={imageloca + objr.value}
                                  alt=""
                                />
                              ) : objr.type === "video" ? (
                                <video
                                  loop
                                  className="user-video-list"
                                  controls
                                >
                                  <source
                                    src={imageloca + objr.value}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <span>
                                  {(objr.type === "filter" ||
                                    objr.type === "json") &&
                                  objr.value !== null
                                    ? JSON.stringify(objr.value.name)
                                    : objr.type === "reference"
                                    ? objr.value.name
                                    : objr.value === "null"
                                    ? ""
                                    : objr.value}
                                </span>
                              )}
                            </td>
                          )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      )}

      <Modal
        dialogClassName="my-modal"
        size="xl"
        show={showPref}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Personalized List Column</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mod-ht">
          <PrefCompo
            pref="user"
            colarray={prefCall}
            tablename={name}
            handleClose={() => handleCloseRefresh()}
          ></PrefCompo>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
  // return(
  //   <div>
  //     <h1>modal</h1>
  //   </div>
  // )
};

export default ModelList;
