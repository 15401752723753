import axios from "axios";
import React, { useState, useContext, useEffect, useRef } from "react";
import {
  createSearchParams,
  useBlocker,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { MarketContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
import RelationList from "./RelationList";
import ApForm from "../ApForm";
import ModelList from "./ModelList";
import ApUser from "../ApUser";
import { toast } from "react-toastify";
import { Modal, Button, Tab, Nav, ListGroup } from "react-bootstrap";
import JoditEditor from "jodit-react";
import FormInnerCompo from "./FormInnerCompo";
import { isMobile } from "react-device-detect";
import "../css/FormCSS.css";
import "../css/relationlist.css";
import InvoiceLabel from "./InvoiceLabel";
import DragModel from "./DargModel";
import ContextMenu from "./ContextMenu";

const FormComponent = () => {
  // useNavigation hook
  const navigation = useNavigate();

  const dataa = useLocation();
  const location = useLocation().state;
  const pathName = useLocation().pathname;
  const [copyRcd, setCopyRcd] = useState([]);
  const [isKV, setIsKV] = useState(false);
  // useContext hook
  const {
    token,
    setShowContainer,
    showContainer,
    contextMenu,
    labelContext,
    loca,
    userDetails,
    imageloca,
    appname,
    user,
    listAccess,
    newAccess,
    formHeight,
  } = useContext(MarketContext);

  // useState hooks
  // const [column_depend, setColumn_depend] = useState([]);
  const [uiflag, setUiflag] = useState(false);
  const [defUiValid, setDefUiValid] = useState([]);
  const [searchParam, setSearchParam] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tabRel, setTabRel] = useState(false);
  const [tabRelation, setTabRelation] = useState({});
  const [page_error, setPageError] = useState(false);
  const [error, setError] = useState("");
  const [page_message, setPageMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [record, setRecord] = useState([]);
  const [rcdView, setRcdView] = useState([]);
  const [refrecord, setRefRecord] = useState({});
  const [button, setButton] = useState([]);
  const [sendProps, setSendProps] = useState("");
  const [mainRecord, setMainRecord] = useState([]);
  const [selection, setSelection] = useState("");
  const [uiScript, setUiScript] = useState([]);
  const [ap_form, setap_form] = useState();
  const [ap_user, setap_user] = useState();
  const [add, setAdd] = useState(false);
  const [multiSv, setMultiSv] = useState("");
  const [showModelList, setshowModelList] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [passwordReadOnly, setPasswordReadOnly] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [showContext, setShowContext] = useState(false);
  const [mscList, setMscList] = useState([]);
  const [isClick, setIsClick] = useState(false);
  const [current_Col_Types, setCurrent_Col_Types] = useState([]);
  const [cid, setCid] = useState(-1);
  const [column_depend, setColumn_depend] = useState([]);
  const [all_ref_cols, setAll_ref_cols] = useState([]);
  const [labelData, setLabelData] = useState(null);
  const [filtarray, setFiltArray] = useState([
    {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
      dc: { id: "", value: "" },
      ch: [],
    },
  ]);
  const [tablabel, setTabLabel] = useState("");
  const [tabId, setTabId] = useState("");
  const [filt, setFilt] = useState();
  const [ref_filter, setref_filter] = useState([]);
  const [showlist, setShowList] = useState(false);
  const [col_mn, setCol_mn] = useState([]);
  const [choice_mn, setChoice_mn] = useState([]);
  const [timeline, setTimeline] = useState("");
  const [columnId, setColumnId] = useState();
  const [objName, setObjName] = useState();
  const [objLabel, setObjLabel] = useState();
  const [objIndex, setObjIndex] = useState();
  const [objType, setObjType] = useState();
  const [record_rq, setrecord_rq] = useState([]);
  const [policyflag, setPolicyFlag] = useState(false);
  const [policyaction, setPolicyAction] = useState([]);
  const [policyFilter, setPolicyFilter] = useState();
  const [reScript, setReScript] = useState([]);
  const [formData, setFormData] = useState();
  const [objdata, setObjData] = useState();
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [runningScript, setRunningScript] = useState(false);
  const [verifyError, setVerifyError] = useState(true);
  const [infoRcdView, setInfoRcdView] = useState([]);
  const [showModelInfo, setShowModelInfo] = useState(false);
  const [infoRcdDetail, setInfoRcdDetail] = useState({});
  const [dcFiltVal, setDcFiltVal] = useState();
  const [downRcdView, setDownRcdView] = useState([]);
  const [activity, setActivity] = useState([]);
  const [secondaryStartIndex, setSecondaryStartIndex] = useState(0);
  const [overlayH, setOverlayH] = useState(0);
  const [secondarycallsCols, setSecondarycallsCols] = useState(new Map());
  const [shouldBlock, setShouldBlock] = useState(false);
  let deftimeline = useRef("");
  const [keyValueJson, setKeyValueJson] = useState({
    properties: [],
  });
  const [groupkeyValue, setGroupKeyValue] = useState({
    properties: [
      {
        name: "",
        choice: [{ value: "" }],
      },
    ],
  });

  // const [i_d, seti_d] = useState(0)
  // const [activeSuggestion, setActiveSuggestion] = useState("");
  const [searchrcd, setSearchrcd] = useState([
    { name: "ap_form", show: false },
    { name: "getFieldValue()", show: false },
    { name: "setFieldValue()", show: false },
  ]);
  const [recordSuggestion, setRecordSuggestion] = useState([
    { name: "ap_form", show: false },
    { name: "ap_user", show: false },
    { name: "getFieldValue()", show: false },
    { name: "setFieldValue()", show: false },
    { name: "setMandatory()", show: false },
    { name: "isMandatory()", show: false },
    { name: "setVisible()", show: false },
    { name: "addOption()", show: false },
    { name: "removeOption()", show: false },
    { name: "removeAllOption()", show: false },
  ]);
  const [userRecordSuggestion, setUserRecordSuggestion] = useState([
    { name: "ap_user", show: false },
    { name: "getUserId()", show: false },
    { name: "getId()", show: false },
    { name: "getLabel ", show: false },
    { name: "getUniId()", show: false },
  ]);

  // useRef hooks
  let tableName = useRef("");
  let rty = useRef("");
  let rid = useRef(0);
  let rcrd = useRef("");
  let tabRe = useRef("");

  let count = useRef(1);
  let chcRcd = useRef([]);
  let uScript = useRef("");
  let listRef = useRef([]);
  let i_d = useRef(0);
  const editor = useRef(null);
  let menuX = useRef(0);
  let menuY = useRef(0);
  let m_rid = useRef(0);
  let checkMenu = useRef(false);
  let refValue = useRef("");
  let rcdRef = useRef("");
  let closeRef = useRef(false);
  let fieldName = useRef("").current;
  let recordNew = useRef("").current;
  useEffect(() => {
    if (searchParam.get("rty") === "new" && dataa.state) {
      ref_cols_insert();
    }
  }, [record, dataa]);

  const ref_cols_insert = () => {
    let rcdJson = dataa.state.ref_cols;
    if (rcdJson) {
      let rdJson = JSON.parse(JSON.stringify(rcdJson));
      var rcd = record;
      for (let i = 0; i < rdJson.length; i++) {
        for (let j = 0; j < rcd.length; j++) {
          if (rcd[j].name === rdJson[i].name) {
            rcd[j].value = rdJson[i].value;
            rcd[j].rcd_info = "true";
          }
        }
      }
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }
  };
  const getSecondaryFields = (ob, id, vl) => {
    let arr = secondarycallsCols.get(ob);

    let pt = "";
    if (secondarycallsCols.get(ob)) {
      var frecord = record;
      for (let p = 0; p < arr.length; p++) {
        if (p > 0) pt += ",";
        pt += JSON.stringify(arr[p]);
      }
      // let st=`{tabName:"${vl} , id:"${id} , columns:${pt}}`
      let st = { tabname: vl, id: id, columns: arr };

      axios
        .post(loca + "/marketplace/get/secondary/record", st, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then((resp) => {
          let data = resp.data;
          let colsRecord = data.colsRecord;
          for (let k = 0; k < colsRecord.length; k++) {
            for (
              let start = secondaryStartIndex;
              start < frecord.length;
              start++
            ) {
              if (frecord[start].formView.path === colsRecord[k].path) {
                frecord[start].value = colsRecord[k].value;
                if (frecord[start].type === "reference") {
                  frecord[start].rcd_info = "true";
                }
              }
            }
          }
          setRecord([...frecord]);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  const getCaretPos = (e, index) => {
    // Getting the textarea element
    let textarea = document.getElementById("script" + index);

    // Adjusting textarea to prevent scrolling
    textarea.style.height = `${e.target.scrollHeight}px`;

    // Appending element to the DOM after textarea
    textarea.insertAdjacentHTML(
      "afterend",
      `<span id = 'dummy'>${textarea.value}</span>`
    );

    // Getting position info of the rectangles inside dummy element
    let rectangles = document.getElementById("dummy").getClientRects();
    let last = rectangles[rectangles.length - 1];

    // Getting position info of the textarea
    let text = document
      .getElementById("script" + index)
      .getBoundingClientRect();

    // Setting coordinates
    let x = last.x + last.width;
    let y = text.y + text.height - last.height;

    // Removing dummy
    document.getElementById("dummy").remove();

    // Returning variables
    return [x, y];
  };

  const onSelection = (type) => {
    let s;
    switch (type) {
      case "onsubmit":
        s = "function onSubmit(){";
        break;
      case "onload":
        s = "function onLoad(){";
        break;
      case "onchange":
        s = "function onChange(val){";
        break;
      case "oncelledit":
        s = "function onCellEdit(val){";
        break;

      case "onreference":
        s = "function onReference(){";
        break;

      default:
        s = "";
        break;
    }
    setSelection(s);
  };

  const searchModule = (nam, where, type) => {
    if (true) {
      if (type === "form") {
        var search = recordSuggestion;
        var abc = [];
        for (var i = 0; i < search.length; i++) {
          var app_m = search[i].name.toLowerCase();
          if (app_m.includes(nam.toLowerCase())) {
            if (where) {
              if (i === 0 || i === 1) {
                abc.push({
                  name: search[i].name,
                  show: true,
                });
              }
            } else {
              if (i > 0) {
                abc.push({
                  name: search[i].name,
                  show: true,
                });
              }
            }
          }
        }
        setSearchrcd([...abc]);
      } else if (type === "user") {
        var search = userRecordSuggestion;
        var abc = [];
        for (var i = 0; i < search.length; i++) {
          var app_m = search[i].name.toLowerCase();
          if (app_m.includes(nam.toLowerCase())) {
            if (where) {
              if (i === 0) {
                abc.push({
                  name: search[i].name,
                  show: true,
                });
              }
            } else {
              if (i > 0) {
                abc.push({
                  name: search[i].name,
                  show: true,
                });
              }
            }
          }
        }
        setSearchrcd([...abc]);
      }
    } else if (nam === "") {
      setSearchrcd([...recordSuggestion]);
    }
  };

  const callFieldList = (tabId, call_check) => {
    var filterstate = filtarray;
    var farray = [];
    if (
      filtarray === "" ||
      filtarray === "undefined" ||
      filtarray === undefined ||
      filtarray === "null" ||
      filtarray === null
    ) {
      farray.push(filt);
      filterstate = farray;
    } else {
      if (filtarray instanceof Array) {
        farray = filtarray;
        filterstate = farray;
      } else {
        farray = filtarray.filter;
        filterstate = farray;
      }
    }
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","name":"marketplace"}}';
    tablere += ',{"table":{"id":"' + tabId + '","name":"","label":""}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
    tablere += ',{"filter":' + JSON.stringify(filterstate) + "}";
    tablere += ',{"timeLine":"' + timeline + '"}]}';
    setList(tablere, call_check);
  };

  const setList = (tablere, call_check) => {
    // setLoading(true);
    axios
      .post(loca + "/marketplace/get/multiple/record", tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((resp) => {
        const listrecord = resp.data;
        var columnarry = [];
        var hd = [];
        var va_ll = [];
        if (listrecord !== "") {
          if ("Error" in listrecord) {
            // this.setState({
            //   loading: false,
            //   page_error: true,
            //   error: listrecord.Error,
            // });
            setLoading(false);
            setPageError(true);
            setError(listrecord.Error);
          } else {
            var scnd = listrecord.formRecordList[2];
            if ("Error" in scnd) {
              if (scnd.Error === "No record found.") {
                // this.setState({ list: [] });
                // setlist([]);
                toast("No record found", { type: "error" });
              }
            } else {
              var page_clicked1 = parseInt(
                listrecord.formRecordList[3].page.page_clicked
              );
              var page_count1 = parseInt(
                listrecord.formRecordList[3].page.page_count
              );
              var page_records1 =
                listrecord.formRecordList[3].page.page_records;
              var record_count1 = parseInt(
                listrecord.formRecordList[3].page.record_count
              );

              var flt = listrecord.formRecordList[5];
              var fltarr = listrecord.formRecordList[5].filter;
              var tmln = listrecord.formRecordList[6].timeLine;
              var filterString = "";
              var leng = fltarr.length;
              for (var f = 0; f < fltarr.length; f++) {
                if (leng === 1 && fltarr[f].cl === "") {
                  break;
                } else {
                  if (f > 0) {
                    filterString += fltarr[f].af + " ";
                  }
                  filterString += fltarr[f].cl + " ";
                  filterString += fltarr[f].mc + " ";
                  filterString += fltarr[f].an + " ";
                }
              }

              for (
                var i = 0;
                i < listrecord.formRecordList[2].records.length;
                i++
              ) {
                var in_vl = [];
                var record_id;
                for (
                  var j = 0;
                  j < listrecord.formRecordList[2].records[i].record.length;
                  j++
                ) {
                  if (i === 0) {
                    if (j === 0) {
                    } else {
                      hd.push({
                        label:
                          listrecord.formRecordList[2].records[i].record[j]
                            .label,
                        name: listrecord.formRecordList[2].records[i].record[j]
                          .name,
                      });
                    }
                  }
                  if (j === 0) {
                    record_id =
                      listrecord.formRecordList[2].records[i].record[j].value;
                  } else if (j === 1) {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value.value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    }
                  } else {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value.value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    }
                  }
                }
                va_ll.push({ colr: in_vl });
              }
              columnarry.push({ heading: hd, rcd: va_ll });
              var cla = JSON.parse(
                JSON.stringify(listrecord.formRecordList[9].column)
              );
              var choicearry = [];
              for (
                var z = 0;
                z < listrecord.formRecordList[9].column.length;
                z++
              ) {
                choicearry.push({
                  id: listrecord.formRecordList[9].column[z].id,
                  name: listrecord.formRecordList[9].column[z].name,
                  label: listrecord.formRecordList[9].column[z].label,
                  value: listrecord.formRecordList[9].column[z].name,
                  choice_order: z * 100 + "",
                  form_column_id: "",
                });
              }
              cla.unshift({ label: "None", name: "none" });
              // setTabLabel(listrecord.formRecordList[1].table.label);
              setPageError(false);
              setLoading(false);
              setChoice_mn(choicearry);
              setCol_mn(cla);
              setTimeline(tmln);
              // if (call_check) {
              //   relationVerify();
              // }
            }
          }
        }
      });
  };

  const callColumnByColId = (id) => {
    // var token = localStorage.getItem("token");
    if (id !== "0" && id !== "none") {
      setColumn_depend([]);
      axios
        .get(loca + "/marketplace/get/columnrecords/" + id, {
          headers: {
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then((resp) => {
          const coldata = resp.data;
          if (coldata !== "") {
            if ("Error" in coldata) {
              setLoading(false);
              setPageError(true);
              setError(coldata.Error);
            } else {
              var col_array = [];
              col_array.push({ id: "", name: "none", label: "None" });
              for (var c = 0; c < coldata.columnRecords.length; c++) {
                col_array.push(coldata.columnRecords[c]);
              }
              var choicearry = [];
              for (var z = 0; z < coldata.columnRecords.length; z++) {
                choicearry.push({
                  id: coldata.columnRecords[z].id,
                  name: coldata.columnRecords[z].name,
                  label: coldata.columnRecords[z].label,
                  value: coldata.columnRecords[z].name,
                  choice_order: z * 100 + "",
                  form_column_id: "",
                });
              }
              setColumn_depend(choicearry);
              setTimeout(() => {}, 1000);
            }
          }
        });
    }
  };

  const [filtRefArray, setFiltRefArray] = useState([
    {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
      dc: { id: "", value: "" },
      ch: [],
    },
  ]);
  const callRefFieldList = (tabId, call_check) => {
    var filterstate = filtRefArray;
    var farray = [];
    if (
      filtRefArray === "" ||
      filtRefArray === "undefined" ||
      filtRefArray === undefined ||
      filtRefArray === "null" ||
      filtRefArray === null
    ) {
      farray.push(filt);
      filterstate = farray;
    } else {
      if (filtRefArray instanceof Array) {
        farray = filtRefArray;
        filterstate = farray;
      } else {
        farray = filtRefArray.filter;
        filterstate = farray;
      }
    }
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","name":"marketplace"}}';
    tablere += ',{"table":{"id":"' + tabId + '","name":"","label":""}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
    tablere += ',{"filter":' + JSON.stringify(filterstate) + "}";
    tablere += ',{"timeLine":"' + timeline + '"}]}';

    setRefList(tablere, call_check);
  };

  const [choice_ref_mn, setChoice_ref_mn] = useState([]);
  const setRefList = (tablere, call_check) => {
    // setLoading(true);
    axios
      .post(loca + "/marketplace/get/multiple/record", tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((resp) => {
        const listrecord = resp.data;
        var columnarry = [];
        var hd = [];
        var va_ll = [];
        if (listrecord !== "") {
          if ("Error" in listrecord) {
            // this.setState({
            //   loading: false,
            //   page_error: true,
            //   error: listrecord.Error,
            // });
            setLoading(false);
            setPageError(true);
            setError(listrecord.Error);
          } else {
            var scnd = listrecord.formRecordList[2];
            if ("Error" in scnd) {
              if (scnd.Error === "No record found.") {
                // this.setState({ list: [] });
                // setlist([]);
                toast("No record found", { type: "error" });
              }
            } else {
              var page_clicked1 = parseInt(
                listrecord.formRecordList[3].page.page_clicked
              );
              var page_count1 = parseInt(
                listrecord.formRecordList[3].page.page_count
              );
              var page_records1 =
                listrecord.formRecordList[3].page.page_records;
              var record_count1 = parseInt(
                listrecord.formRecordList[3].page.record_count
              );

              var flt = listrecord.formRecordList[5];
              var fltarr = listrecord.formRecordList[5].filter;
              var tmln = listrecord.formRecordList[6].timeLine;
              var filterString = "";
              var leng = fltarr.length;
              for (var f = 0; f < fltarr.length; f++) {
                if (leng === 1 && fltarr[f].cl === "") {
                  break;
                } else {
                  if (f > 0) {
                    filterString += fltarr[f].af + " ";
                  }
                  filterString += fltarr[f].cl + " ";
                  filterString += fltarr[f].mc + " ";
                  filterString += fltarr[f].an + " ";
                }
              }

              for (
                var i = 0;
                i < listrecord.formRecordList[2].records.length;
                i++
              ) {
                var in_vl = [];
                var record_id;
                for (
                  var j = 0;
                  j < listrecord.formRecordList[2].records[i].record.length;
                  j++
                ) {
                  if (i === 0) {
                    if (j === 0) {
                    } else {
                      hd.push({
                        label:
                          listrecord.formRecordList[2].records[i].record[j]
                            .label,
                        name: listrecord.formRecordList[2].records[i].record[j]
                          .name,
                      });
                    }
                  }
                  if (j === 0) {
                    record_id =
                      listrecord.formRecordList[2].records[i].record[j].value;
                  } else if (j === 1) {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value.value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    }
                  } else {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value.value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    }
                  }
                }
                va_ll.push({ colr: in_vl });
              }
              columnarry.push({ heading: hd, rcd: va_ll });
              var cla = JSON.parse(
                JSON.stringify(listrecord.formRecordList[9].column)
              );
              var choicearry = [];
              for (
                var z = 0;
                z < listrecord.formRecordList[9].column.length;
                z++
              ) {
                choicearry.push({
                  id: listrecord.formRecordList[9].column[z].id,
                  name: listrecord.formRecordList[9].column[z].name,
                  label: listrecord.formRecordList[9].column[z].label,
                  value: listrecord.formRecordList[9].column[z].name,
                  choice_order: z * 100 + "",
                  form_column_id: "",
                });
              }
              cla.unshift({ label: "None", name: "none" });
              setPageError(false);
              setChoice_ref_mn(choicearry);
              setTimeline(tmln);
            }
          }
        }
      });
  };

  const fcf_dynamicKeyValue = (
    value,
    index,
    keyInnerIndex,
    keyOuterIndex,
    frecord
  ) => {
    let props = value;
    let abc = frecord[index].value[keyOuterIndex];
    if (keyInnerIndex === 0) {
      // console.log(
      //   frecord[index].value,
      //   Object.keys(abc),
      //   Object.keys(abc).includes(props)
      // );
      for (let i = 0; i < frecord[index].value.length; i++) {
        const ob = frecord[index].value[i];
        if (Object.keys(ob).includes(props)) {
          return;
        }
      }
      var key = Object.keys(abc)[0];
      var val = Object.values(abc)[0];
      delete abc[key];
      if (val) {
        abc[props] = val;
      } else {
        abc[props] = "";
      }
    } else {
      var key = Object.keys(abc)[0];
      if (key) {
        abc[key] = props;
      } else {
        abc[""] = props;
      }
    }
    setRecord([...frecord]);
  };

  const formChangefn = (
    vl,
    index,
    ob,
    type,
    id,
    vrf,
    obj,
    outerIndex,
    innerIndex,
    nme,
    properties
  ) => {
    setShouldBlock(true);
    setSendProps(vl);
    var frecord = record;

    if (policyFilter !== undefined && policyFilter !== "") {
      let arr = [];
      a: for (let n = 0; n < frecord.length; n++) {
        for (let m = 0; m < policyaction.length; m++) {
          if (policyaction[m].market_column_id === frecord[n].id) {
            arr.push({
              id: frecord[n].id,
              uivalid: JSON.parse(JSON.stringify(frecord[n].uivalid)),
            });
            if (arr.length === policyaction.length) {
              break a;
            }
          }
        }
      }
      setDefUiValid(arr);
      var pfilt = policyFilter.filter;
      for (let i = 0; i < pfilt.length; i++) {
        let p_policy = pfilt;
        let name = pfilt[i].co;
        let sign = pfilt[i].mc;
        let answer = pfilt[i].an;
        if (pfilt[i].ct === "reference") {
          answer = pfilt[i].rf.value;
        }
        if (name === ob) {
          switch (sign) {
            case "is":
              if (answer === vl) {
                for (let j = 0; j < frecord.length; j++) {
                  for (let k = 0; k < policyaction.length; k++) {
                    if (frecord[j].id === policyaction[k].market_column_id) {
                      if (policyaction[k].visible != "null") {
                        frecord[j].uivalid.visible = policyaction[k].visible;
                      }
                      if (policyaction[k].read_only != "null") {
                        frecord[j].uivalid.read_only =
                          policyaction[k].read_only;
                      }
                      if (policyaction[k].mandatory != "null") {
                        frecord[j].uivalid.mandatory =
                          policyaction[k].mandatory;
                      }
                      if (p_policy[i].an === "true") {
                        p_policy[i].an = "false";
                      } else if (p_policy[i].an === "false") {
                        p_policy[i].an = "true";
                      }
                      // console.log(frecord);
                      let pp = {
                        filter: p_policy,
                        timeline: policyFilter.timeLine,
                      };
                      setPolicyFilter(pp);
                      setUiflag(true);
                      // console.log(policyaction);
                      // frecord[j].uivalid.read_only = policyaction[k].read_only;
                    }
                  }
                }
              } else {
                if (uiflag) {
                  for (let o = 0; o < frecord.length; o++) {
                    for (let p = 0; p < defUiValid.length; p++) {
                      if (frecord[o].id === defUiValid[p].id) {
                        frecord[o].uivalid = defUiValid[p].uivalid;
                      }
                    }
                  }
                  setUiflag(false);
                }
              }
              break;
            case ">":
              if (answer > vl) {
                setPolicyFlag(true);
              }
              break;
            case "<":
              if (answer < vl) {
                setPolicyFlag(true);
              }
              break;

            default:
              break;
          }
        }
      }
    }
    if (tableName.current === "reference_filter") {
      if (ob === "market_column_id") {
        callColumnByColId(id);
      }
    }
    if (type === "key_value") {
      let crnt_rcd = record[index];
      for (let ab = 0; ab < record.length; ab++) {
        if (
          record[ab].type === "reference" &&
          record[ab].refTable.value === crnt_rcd.depend_table
        ) {
          record[ab].value.id = id;
          record[ab].value.name = vl;
        }
      }
      crnt_rcd.selected = true;
      crnt_rcd.gkv = properties;
      setRefRecord({});
      let tmp_prpp = { properties: [] };
      if (crnt_rcd.gkv) {
        crnt_rcd.selected = true;
        for (let i = 0; i < crnt_rcd.gkv.column_value.properties.length; i++) {
          tmp_prpp.properties.push({ name: "", value: "" });
        }
        crnt_rcd.hidden_val = tmp_prpp;
      } else {
        crnt_rcd.selected = false;
      }
      if (vl.length > 2) {
        if (vrf === true) {
          if (frecord[index].name === ob) {
            frecord[index].value = vl;
            setRecord([...frecord]);
            setRefRecord({});
          }
        } else {
          if (frecord[index].name === ob) {
            frecord[index].value = vl;
            setRecord([...frecord]);
          }

          var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
          veri += '{"tabvalue":"' + vl + '"}]}';
          var rff = [];
          axios
            .post(loca + `/marketplace/reference/record`, veri.toString(), {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + token,
                "market-application": appname,
              },
            })
            .then(
              (resp) => {
                const refrencercd = resp.data;
                if ("Error" in refrencercd) {
                } else {
                  rff = {
                    index: index,
                    record: refrencercd.referenceRecordList[2].records,
                  };
                  setShowContainer(true);
                  setRefRecord({ ...rff });
                }
              },
              (error) => {
                console.log(error);
              }
            );
        }
      } else {
        if (frecord[index].name === ob) {
          frecord[index].value = vl;
          if (vl === "") {
            for (let ab = 0; ab < record.length; ab++) {
              if (
                record[ab].type === "reference" &&
                record[ab].refTable.value === crnt_rcd.depend_table
              ) {
                record[ab].value.id = "0";
                record[ab].value.name = vl;
                break;
              }
            }
            frecord[index].hidden_val = "";
          }
          setRecord([...frecord]);
          setRefRecord({});
        }
      }
      if (crnt_rcd.value.length > 1) {
        crnt_rcd.uivalid.mandatory = "true";
      } else {
        crnt_rcd.uivalid.mandatory = "false";
      }
    }
    if (type === "key_value_hidden_val") {
      let current_rcd = record[index];
      let curnt_prop_gkv = current_rcd.gkv.column_value.properties;
      let curnt_prop_gkv_ind =
        current_rcd.gkv.column_value.properties[outerIndex];
      let curnt_prop_hiddenvl = current_rcd.hidden_val.properties;
      let curnt_prop_hiddenvl_ind =
        current_rcd.hidden_val.properties[outerIndex];
      let nme = curnt_prop_gkv_ind.name;
      let vll = curnt_prop_gkv_ind.choice[innerIndex].value;
      curnt_prop_hiddenvl_ind.name = nme;
      curnt_prop_hiddenvl_ind.value = vll;
    }
    if (type === "reference") {
      if (ob === "market_table_id" && vl === "") {
        setChoice_mn([]);
      }
      if (tableName.current === "reference_filter") {
        if (ob === "market_column_id") {
          callColumnByColId(id);
        }
      }
      if (ob === "market_table_id") {
        callTableColumn(vl, false);
      } else if (
        (ob === "market_table_id" ||
          ob === "mar_market_table_id" ||
          obj === "market_table") &&
        tableName.current !== "market_table_access" &&
        vrf
      ) {
        callTableColumn(vl, false);
      }
      if (ob === "reference_id") {
        callRefFieldList(id, true);
      }
      if (vrf === false) {
        frecord[index].clicked = false;
      }
      if (vl.length > 2) {
        if (vrf === true) {
          if (frecord[index].name === ob) {
            frecord[index].value.name = vl;
            frecord[index].clicked = true;
            frecord[index].value.id = id;
            frecord[index].rcd_info = "true";
            // setState({ record: frecord, refrecord: [] });

            // getSecondaryFields(
            //   index,
            //   ob,
            //   type,
            //   id,
            //   frecord[index]?.refTable?.value
            // );
            setRecord([...frecord]);
            setRefRecord({});
            validationfn(vl, index, ob, type, id);
          }
        } else {
          if (frecord[index].name === ob) {
            frecord[index].value.name = vl;
            frecord[index].value.id = id;
            frecord[index].rcd_info = "true";
            setRecord([...frecord]);
          }

          var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
          veri += '{"tabvalue":"' + vl + '"}]}';
          var rff = [];
          axios
            .post(loca + `/marketplace/reference/record`, veri.toString(), {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + token,
                "market-application": appname,
              },
            })
            .then(
              (resp) => {
                const refrencercd = resp.data;
                if ("Error" in refrencercd) {
                } else {
                  rff = {
                    index: index,
                    record: refrencercd.referenceRecordList[2].records,
                  };
                  setShowContainer(true);
                  setRefRecord({ ...rff });
                }
              },
              (error) => {
                console.log(error);
              }
            );
        }
      } else {
        if (frecord[index].name === ob) {
          frecord[index].value.name = vl;
          if (vl === "") {
            frecord[index].value.id = "0";
          } else {
            frecord[index].value.id = id;
          }
          // frecord[secondaryStartIndex].value = "";
          frecord[index].verified = "";
          setRecord([...frecord]);
          setRefRecord({});
        }
      }
    } else if (type === "depend_table") {
      if (frecord[index].name === ob) {
        frecord[index].value.name = vl;
        frecord[index].value.id = id;
        frecord[index].verified = "";
        frecord[index].rcd_info = "true";
        setRecord([...frecord]);
      }
    } else if (type === "dynamic_key_value") {
      fcf_dynamicKeyValue(vl, index, innerIndex, outerIndex, frecord);
    } else {
      if (frecord[index].name === ob) {
        if (frecord[index].type === "String" && vl.length > 0) {
          if (frecord[index].validation === "number") {
            if (/^[0-9]*$/.test(vl)) {
              frecord[index].value = vl;
              setRecord([...frecord]);
            }
          } else if (frecord[index].validation === "name") {
            if (/^[a-z][a-z0-9_]*$/.test(vl)) {
              frecord[index].value = vl;
              setRecord([...frecord]);
            } else {
              vl = frecord[index].value;
            }
          } else if (frecord[index].validation === "character") {
            if (/^[a-zA-Z\s]+$/.test(vl)) {
              frecord[index].value = vl;
              setRecord([...frecord]);
            }
          } else if (frecord[index].validation === "withoutSpecialCharacter") {
            if (/^[_A-z0-9\s]*((-|\s)*[_A-z0-9])*$/.test(vl)) {
              frecord[index].value = vl;
              setRecord([...frecord]);
            }
          } else if (frecord[index].validation === "zipCode") {
            if (/^[0-9]{5}(?:-[0-9]{4})?$/.test(vl)) {
              frecord[index].value = vl;
              setRecord([...frecord]);
            }
          } else if (frecord[index].validation === "decimal") {
            if (/^\d*\.?\d*$/.test(vl)) {
              frecord[index].value = vl;
              setRecord([...frecord]);
            }
          } else if (frecord[index].validation === "ipAddress") {
            if (/((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}$/.test(vl)) {
              frecord[index].value = vl;
              setRecord([...frecord]);
              //Ipv4 = (([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])
              //Ipv6 = ((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}
            }
          } else {
            // if (/^[a-zA-Z0-9_\s]*$/.test(vl)) {
            frecord[index].value = vl;
            setRecord([...frecord]);
            // }
          }
        } else if (frecord[index].type === "group_key_value") {
          if (nme === "name") {
            frecord[index].value.properties[outerIndex][nme] = vl;
          }
          if (nme === "choice" && innerIndex !== null) {
            frecord[index].value.properties[outerIndex][nme][innerIndex].value =
              vl;
          }
          setRecord([...frecord]);
        } else {
          frecord[index].value = vl;
          setRecord([...frecord]);
        }
      }
    }
    for (let i = 0; i < uiScript.length; i++) {
      let field = uiScript[i].field.name;
      let func = uiScript[i].script;
      let type = uiScript[i].type;
      if (field === ob && type === "onchange") {
        onChange(func, vl, obj);
      }
    }
  };

  const fieldverify = (type, vl) => {
    if (type === "String" || type === "filter") {
      // if (/^[a-zA-Z_\w]*$/g.test(vl)) {
      return "verified";
      // } else {
      //   return "unverified";
      // }
    }

    if (type === "email") {
      if (/^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(vl)) {
        return "verified";
      } else {
        setVerifyError("Please verify your email!");
        return "unverified";
      }
    }
    if (type === "int") {
      // const regex = new RegExp(/[0-9]/);
      if (/^[0-9]*$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "datetime") {
      // if (
      //   /^(?:(?:(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec))(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:(?:0?2|(?:Feb))(\/|-|\.)(?:29)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
      //     vl
      //   )
      // ) {
      //   return "verified";
      // } else {
      //   return "unverified";
      // }
      return "verified";
    }
  };

  const validationfn = (vl, index, ob, type, id) => {
    var formrecord = record;

    if (type === "reference") {
      if (vl !== "") {
        var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
        veri += '{"tabvalue":"' + vl + '"}]}';
        axios
          .post(loca + `/marketplace/reference/verify`, veri.toString(), {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
              "market-application": appname,
            },
          })
          .then((resp) => {
            const refrencercd = resp.data;
            if (refrencercd.Result === "Unverified") {
              formrecord[index].verified = "unverified";
              // this.setState({ record: formrecord });
              setRecord([...formrecord]);
            } else {
              formrecord[index].verified = "verified";
              // this.setState({ record: formrecord, refrecord: [] });
              setRecord([...formrecord]);
              setRefRecord([]);
            }

            // this.setState({ record: formrecord });
            setRecord([...formrecord]);

            return;
          });
      } else {
        formrecord[index].verified = "initial";
        // this.setState({ record: formrecord });
        setRecord([...formrecord]);
      }
    } else {
      var minLength = formrecord[index].uivalid.min_length;
      if (minLength !== 0 && vl.length < minLength) {
        setVerifyError("Length of character or number should be " + minLength);
        formrecord[index].verified = "unverified";
      } else {
        if (formrecord[index].name === ob) {
          if (type === "int" || vl !== "") {
            formrecord[index].verified = fieldverify(
              formrecord[index].type,
              vl
            );
          } else {
            formrecord[index].verified = "initial";
          }
        }
      }
      setRecord([...formrecord]);
    }
  };

  const callbtn = async (nam) => {
    try {
      setSubmitLoading(true);
      setShouldBlock(false);
      setIsClick(true);
      for (let i = 0; i < uiScript.length; i++) {
        let func = uiScript[i].script;
        let type = uiScript[i].type;
        if (type === "onsubmit") {
          onSubmit(func);
        }
      }

      var btn = button;
      var mnrecord = mainRecord;
      var rcd = record;
      var mandatory = [];
      var unverified = [];
      let imggg = 0;
      for (var i = 0; i < rcd.length; i++) {
        // console.log(rcd[i]);
        if (rcd[i].uivalid.visible === "true") {
          if (rcd[i].uivalid.mandatory === "true") {
            if (rcd[i].value === "") {
              mandatory.push(rcd[i].label.name);
            } else if (
              rcd[i].type === "reference" &&
              rcd[i].value.name === ""
            ) {
              mandatory.push(rcd[i].label.name);
            } else if (
              rcd[i].type === "multi_select" &&
              rcd[i].value.record.length === 0
            ) {
              mandatory.push(rcd[i].label.name);
            } else if (rcd[i].type === "key_value") {
              if (rcd[i].value.properties) {
                let propArr = rcd[i].value.properties;
                for (let a = 0; a < propArr.length; a++) {
                  if (propArr[a].name === "" || propArr[a].value === "") {
                    mandatory.push(rcd[i].label.name);
                    break;
                  }
                }
              } else {
                mandatory.push(rcd[i].label.name);
              }
            }
          }
          if (
            rcd[i].type === "String" ||
            rcd[i].type === "int" ||
            rcd[i].type === "email" ||
            rcd[i].type === "date"
          ) {
            var veri = fieldverify(rcd[i].type, rcd[i].value);
            if (veri === "unverified" && rcd[i].uivalid.mandatory !== "false") {
              unverified.push(rcd[i].label.name);
            }
          }
          if (
            rcd[i].type === "image_upload" &&
            rcd[i].fdata&&
            rcd[i].value !== "" &&
            rcd[i].value.includes("blob")
          ) {
            if (mandatory.length === 0) {
              const ims = await uploadImage(
                rcd[i],
                imggg,
                rcd[i].file_path,
                rcd[i].compress
              );
              rcd[i].value = ims;
              rcdRef.current = ims;
              imggg++;
            }
          }
          if (rcd[i].type === "upload_pdf" && rcd[i].fdata) {
            if (mandatory.length === 0) {
              const ims = await uploadPdf(rcd[i], rcd[i].file_path);
              rcd[i].value = ims;
              rcdRef.current = ims;
              imggg++;
            }
          }
          if (
            rcd[i].type === "video" &&
            rcd[i].value !== "" &&
            rcd[i].value.includes("blob")
          ) {
            if (mandatory.length === 0) {
              const ims = await uploadVideo(rcd[i], imggg, null);
              rcd[i].value = ims;
              rcdRef.current = ims;
            }
          }
          if (rcd[i].type === "key_value") {
            if (rcd[i].hidden_val) {
              rcd[i].value = rcd[i].hidden_val;
            } else {
              rcd[i].value = { properties: [] };
            }
          }
        }
      }
      var btntype = "";
      var btnRtype = "";
      var foundbtn = false;

      for (var ij = 0; ij < btn.length; ij++) {
        if (btn[ij].value === nam) {
          btntype = btn[ij].returnWebLocation;
          btnRtype = btn[ij].recordReturnType;
          foundbtn = true;
          break;
        }
      }
      if (foundbtn === true && btntype !== "") {
        setPageError(false);
        setError("");
        setLoading(false);
        setPageMessage(false);
        setMessage("");
        //
        if (mandatory.length === 0 && unverified.length === 0) {
          for (let i = 0; i < rcd.length; i++) {
            if (rcd[i].type === "html") {
            }
          }
          if (btnRtype === "html") {
            var ht_ml = "";
            var script = "";
            for (let i = 0; i < rcd.length; i++) {
              if (rcd[i].type === "html") {
                ht_ml = rcd[i].value;
              }
              if (rcd[i].name === "script") {
                script = rcd[i].value;
              }
            }
            navigation("/htmlcall", { state: { html: ht_ml, script: script } });
          } else if (btnRtype === "Grid") {
            navigation("/clientinforecord", {
              state: { rid: rid.current, tableName: tableName.current },
            });
          } else {
            if ("appRecord" in mnrecord) {
              mnrecord.appRecord[2].record = rcd;
            } else if ("columnRecord" in mnrecord) {
              mnrecord.columnRecord[2].record = rcd;
            } else if ("formRecord" in mnrecord) {
              mnrecord.formRecord[2].record = rcd;
            } else if ("choiceRecord" in mnrecord) {
              mnrecord.choiceRecord[2].record = rcd;
            } else if ("labelRecord" in mnrecord) {
              mnrecord.labelRecord[2].record = rcd;
            } else if ("buttonRecord" in mnrecord) {
              mnrecord.buttonRecord[2].record = rcd;
            } else if ("tableRecord" in mnrecord) {
              mnrecord.tableRecord[2].record = rcd;
            } else if ("typeRecord" in mnrecord) {
              mnrecord.typeRecord[2].record = rcd;
            } else if ("uivalidationRecord" in mnrecord) {
              mnrecord.uivalidationRecord[2].record = rcd;
            } else if ("languageRecord" in mnrecord) {
              mnrecord.languageRecord[2].record = rcd;
            } else if ("userRecord" in mnrecord) {
              mnrecord.userRecord[2].record = rcd;
            } else {
            }

            if (btn[ij].call_type === "run_script") {
              let app = "";
              for (let i = 0; i < rcd.length; i++) {
                if (rcd[i].name === "market_application") {
                  app = rcd[i].value;
                }
              }
              var jn = {
                button: nam,
                recordId: rid.current,
                table: tableName.current,
                application: app,
                type: "manual",
                script: "",
              };
              setRunningScript(true);
              axios
                .post(loca + btn[ij].webUrl, jn, {
                  headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + token,
                    "market-application": appname,
                  },
                })
                .then((resp) => {
                  setSubmitLoading(false);
                  const rcd = resp.data;
                  if (rcd.download) {
                    pdfDownload(rcd.label_details[0]);
                  }
                  if (rcd !== "") {
                    setRunningScript(false);
                    if ("Error" in rcd) {
                      setSubmitLoading(false);
                      setPageError(true);
                      setError(rcd.Error);
                    } else {
                      setSubmitLoading(false);
                      var msg = rcd.message;
                      if (msg !== "") {
                        setPageMessage(true);
                        setMessage(msg);
                        // getRecordForm(rid.current, "");
                      }
                      if (btntype === "NextPage") {
                        var nextP = btn[ij].nextPage;
                        if (nextP === "List") {
                        }
                      }
                    }
                  }
                })
                .finally(() => {
                  setIsClick(false);
                });
            } else {
              axios
                .post(loca + btn[ij].webUrl, mnrecord, {
                  headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + token,
                    "market-application": appname,
                  },
                })
                .then(
                  (resp) => {
                    setSubmitLoading(false);
                    let rcdd = resp.data;
                    if (rcdd !== "" && rcdd !== null) {
                      if ("Error" in rcdd) {
                        toast(rcdd.Error, {
                          position: "bottom-center",
                          theme: "colored",
                          type: "error",
                        });
                      } else {
                        let r_id = 0;
                        let msg = "Successfull";

                        if ("appRecord" in rcdd) {
                          r_id = rcdd.appRecord[2].record[0].value;
                        } else if ("columnRecord" in rcdd) {
                          r_id = rcdd.columnRecord[2].record[0].value;
                        } else if ("formRecord" in rcdd) {
                          r_id = rcdd.formRecord[2].record[0].value;
                          let isMessagePresent = false;
                          let messageValue = "";
                          rcdd.formRecord.forEach((section) => {
                            if (section.hasOwnProperty("message")) {
                              isMessagePresent = true;
                              messageValue = section.message;
                            }
                          });
                          if (isMessagePresent) {
                            msg = messageValue;
                          }
                        } else if ("choiceRecord" in rcdd) {
                          r_id = rcdd.choiceRecord[2].record[0].value;
                        } else if ("labelRecord" in rcdd) {
                          r_id = rcdd.labelRecord[2].record[0].value;
                        } else if ("buttonRecord" in rcdd) {
                          r_id = rcdd.buttonRecord[2].record[0].value;
                        } else if ("tableRecord" in rcdd) {
                          r_id = rcdd.tableRecord[2].record[0].value;
                        } else if ("typeRecord" in rcdd) {
                          r_id = rcdd.typeRecord[2].record[0].value;
                        } else if ("uivalidationRecord" in rcdd) {
                          r_id = rcdd.uivalidationRecord[2].record[0].value;
                        } else if ("languageRecord" in rcdd) {
                          r_id = rcdd.languageRecord[2].record[0].value;
                        } else if ("userRecord" in rcdd) {
                          r_id = rcdd.userRecord[1].record[0].value;
                        } else {
                        }
                        setLoading(false);
                        if (btn[ij].returnWebLocation === "NextPage") {
                          localStorage.removeItem("pageClicked");
                          navigation({
                            pathname: user + "/list",
                            search: createSearchParams({
                              tableName: tableName.current,
                            }).toString(),
                          });
                          // navigation("/list", {
                          //   state: { listName: tableName.current },
                          // });
                        } else {
                          setLoading(true);
                          getFormDetails();
                          checkMenu.current += false;
                        }
                        if (nam.toLowerCase() === "insert") {
                          toast("Inserted Successfully", {
                            position: "bottom-center",
                            theme: "colored",
                            type: "success",
                          });
                        } else if (nam.toLowerCase() === "update") {
                          toast("Updated Successfully", {
                            position: "bottom-center",
                            theme: "colored",
                            type: "success",
                            style: {
                              marginBottom: userDetails.OS !== "null" ? 12 : 0,
                            },
                          });
                        } else if (nam.toLowerCase() === "delete") {
                          toast("Deleted Successfully", {
                            position: "bottom-center",
                            theme: "colored",
                            type: "success",
                          });
                        } else {
                          toast(msg, {
                            position: "bottom-center",
                            theme: "colored",
                            type: "success",
                          });
                        }
                      }
                    } else {
                      toast("Unexpected Error", {
                        position: "bottom-center",
                        theme: "colored",
                        type: "error",
                      });
                    }
                  },
                  (error) => {
                    setSubmitLoading(false);
                    toast("Something went wrong", {
                      position: "bottom-center",
                      theme: "colored",
                      type: "error",
                    });
                  }
                )
                .finally(() => {
                  setSubmitLoading(false);
                  setIsClick(false);
                });
            }
          }
        } else {
          setIsClick(false);
          setSubmitLoading(false);
          var error_String = "";
          if (mandatory.length > 0) {
            error_String += " Check mandatory fields not set: " + mandatory;
          }
          if (unverified.length > 0) {
            error_String += " Fields unverified:" + unverified;
          }

          setLoading(false);
          setError(error_String);
          setPageError(true);

          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }
      } else {
        this.setState({
          page_error: true,
          error: error_String,
        });
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        // this.props.unmountMe();
        // }
      }
    } catch (error) {
      setSubmitLoading(false);
      setIsClick(false);
      setLoading(false);
      toast("Something went wrong", {
        position: "bottom-center",
        theme: "colored",
        type: "error",
      });
    }
  };

  const setMSC = (val, index, name, type, id) => {
    formChangefn(val, index, name, type, id, true);
  };

  const setContextMenu = (val, x, y) => {
    if (val === 2) {
      menuX.current = x;
      menuY.current = y;
      setShowContextMenu(true);
    } else {
      setShowContextMenu(false);
    }
  };

  const setContext = (val, x, y) => {
    if (val === 2) {
      menuX.current = x;
      menuY.current = y;
      setShowContext(true);
    } else {
      setShowContext(false);
    }
  };

  const generatePassword = () => {
    let frecord = record;
    let count = 0;
    var chars =
      "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var passwordLength = 8;
    var password = "";
    for (var i = 0; i < passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    for (let i = 0; i < frecord.length; i++) {
      if (frecord[i].name === "password") {
        frecord[i].value = password;
        count++;
      }
      if (frecord[i].name === "is_reset_password") {
        frecord[i].value = "true";
        count++;
      }
      if (count === 2) {
        break;
      }
    }
    setRecord([...frecord]);
    setPasswordReadOnly(true);
  };

  const setRefRcd = (vl, index, ob, type, id, properties) => {
    formChangefn(vl, index, ob, type, id, true, "", "", "", "", properties);
    getSecondaryFields(ob, id, record[index]?.refTable?.value);
    // setRecord([...record]);
    // setRefRecord({});
    // validationfn(vl, index, ob, type, id);
  };

  const getFieldValue = async (col_id) => {
    var rcd = record;
    if (rcd !== "null" && rcd !== "") {
      for (var r = 0; r < rcd.length; r++) {
        if (rcd[r].id === col_id) {
          return rcd[r].value;
        }
      }
    }
  };

  const setcolumn = async (index, name, type, col, readonly) => {
    if (readonly === "false") {
      var rf = record_rq.referenceQualifier;
      // var ref_filt = ref_filter;
      let ref_filt = [];
      let ref_check = false;
      let filtt = [];
      for (var r = 0; r < rf.length; r++) {
        if (rf[r].marketColumn.id === col && "filter" in rf[r]) {
          filtt = rf[r].filter.filter;
          for (var f = 0; f < filtt.length; f++) {
            if (
              filtt[f].mc === "is dependent" ||
              filtt[f].ct === "depend_table"
            ) {
              var col_name = filtt[f].dc.value;
              var co_label = filtt[f].dc.label;
              let ab = await getFieldValue(filtt[f].rf.id);
              let filtt1 = {};
              filtt1.co = col_name;
              filtt1.cl = co_label;
              filtt1.ct = type;
              filtt1.mc = "is";
              filtt1.an = "";
              filtt1.af = "";
              filtt1.rf = { id: ab.id, value: ab.name };
              filtt1.dc = { id: 0, value: "" };
              ref_filt.push(filtt1);
            }
          }
        }
      }
      if (ref_filt.length === 0) {
        const refarray = {
          filter: [
            {
              co: "",
              cl: "",
              mc: "",
              an: "",
              ct: "",
              af: "",
              rf: { id: "", value: "" },
              dc: { id: "", value: "" },
            },
          ],
        };
        setref_filter(refarray);
      } else {
        const refarray = {
          filter: ref_filt,
        };
        setref_filter(refarray);
      }

      setColumnId(col);
      setObjIndex(index);
      setObjType(type);
      setObjName(name);
      setshowModelList(true);
      setTimeout(() => {
        if (name === "field_id" || name === "market_column_id") {
          if (ref_filter.length > 0) {
            setReference();
          }
        }
      }, 500);
    }
  };

  useEffect(() => {
    if (ref_filter.length > 0) {
      let tabN = objName.split("_id")[0];
      setReference(tabN);
    }
  }, [ref_filter]);

  const clickRefrence = (e, id, name, type, index, label, readonly, tf) => {
    setIsKV(tf);
    e.preventDefault();
    setcolumn(index, name, type, id, readonly);
    setObjLabel(label);
    setObjName(name);
  };

  const getSingleInfo = (
    e,
    obj,
    id,
    name,
    type,
    index,
    label,
    readonly,
    depend_column
  ) => {
    e.preventDefault();
    let infoRecord = {};
    if (obj.type === "depend_table") {
      infoRecord.tabName = "market_column";
      infoRecord.tabid = obj.value.id;
    } else {
      infoRecord.tabName = obj.refTable.value;
      infoRecord.tabid = obj.value.id;
    }
    closeRef.current = true;
    if (infoRecord.tabid != "0") {
      axios
        .get(
          loca +
            `/marketplace/get/singlerecord/${infoRecord.tabName}/${infoRecord.tabid}`,
          {
            headers: {
              authorization: "Bearer " + token,
              "market-application": appname,
            },
          }
        )
        .then(
          (resp) => {
            const record = resp.data;

            var mmm;
            if ("userRecord" in record) {
              mmm = record.userRecord[2].record;
              infoRecord.tabLabel = record.userRecord[1].table.label;
            } else {
              mmm = record.formRecord[2].record;
              infoRecord.tabLabel = record.formRecord[1].table.label;
            }
            setInfoRcdDetail(infoRecord);

            let rcd = [];
            if ("Error" in record) {
              navigation("/dashboard", { state: { error: record.Error } });
            } else {
              if ("formRecord" in record) {
                rcd = record.formRecord[2].record;
              } else if ("buttonRecord" in record) {
                rcd = record.buttonRecord[2].record;
              } else if ("tableRecord" in record) {
                rcd = record.tableRecord[2].record;
              } else if ("typeRecord" in record) {
                rcd = record.typeRecord[2].record;
              } else if ("uivalidationRecord" in record) {
                rcd = record.uivalidationRecord[2].record;
              } else if ("languageRecord" in record) {
                rcd = record.languageRecord[2].record;
              } else if ("userRecord" in record) {
                rcd = record.userRecord[2].record;
              }
            }
            if (tableName.current === "reference_filter") {
              axios
                .get(loca + `/marketplace/get/refFilter/${rid.current}`, {
                  headers: {
                    // "Content-Type": "application/json",
                    authorization: "Bearer " + token,
                  },
                })
                .then(
                  (resp) => {
                    const coldata = resp.data;
                    if (coldata !== "") {
                      if ("Error" in coldata) {
                        setLoading(false);
                        setPageError(true);
                        setError(coldata.Error);
                      } else {
                        var col_array = [];
                        col_array.push({ id: "", name: "none", label: "None" });
                        for (var c = 0; c < coldata.columnRecords.length; c++) {
                          col_array.push(coldata.columnRecords[c]);
                        }
                        var choicearry = [];
                        for (var z = 0; z < coldata.columnRecords.length; z++) {
                          choicearry.push({
                            id: coldata.columnRecords[z].id,
                            name: coldata.columnRecords[z].name,
                            label: coldata.columnRecords[z].label,
                            value: coldata.columnRecords[z].name,
                            choice_order: z * 100 + "",
                            form_column_id: "",
                          });
                        }
                        setColumn_depend(choicearry);
                      }
                    }
                  },
                  (error) => {
                    // setLoading(false)
                    let err = {
                      message: error.message,
                      code: error.response.status,
                    };
                    navigation("/error", { state: { err: err } });
                  }
                );
            }
            for (let i = 0; i < rcd.length; i++) {
              rcd[i].index = i;
            }

            rcd.sort((a, b) =>
              parseInt(a.formView.pn) > parseInt(b.formView.pn)
                ? 1
                : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                ? -1
                : 0
            );
            let rd = [];
            let rdd = [];
            let left_rd = [];
            let left_check = false;
            let downRcd = [];
            for (let i = 0; i < rcd.length; i++) {
              if (rcd[i].type === "activity") {
                downRcd.push(rcd[i]);
              } else if (rcd[i].formView.position === "left") {
                // rcd[i].index = i;
                rd.push(rcd[i]);
                if (
                  i + 1 < rcd.length &&
                  rcd[i + 1].formView.position === "full"
                ) {
                  left_check = true;
                }
              } else if (rcd[i].formView.position === "right") {
                // rcd[i].index = i;
                rd.push(rcd[i]);
                if (
                  i === rcd.length - 1 ||
                  rcd[i + 1].formView.position === "full"
                ) {
                  rdd.push({
                    split: "true",
                    rcd: rd,
                    formView: { co: rd[0].formView.co },
                  });
                  for (let j = 0; j < left_rd.length; j++) {
                    rdd.push(left_rd[j]);
                  }
                  rd = [];
                  left_check = false;
                  left_rd = [];
                }
              } else {
                if (left_check) {
                  rcd[i].split = "false";
                  // rcd[i].index = i;
                  left_rd.push(rcd[i]);
                } else {
                  rcd[i].split = "false";
                  // rcd[i].index = i;
                  rdd.push(rcd[i]);
                }
              }
            }
            for (let i = 0; i < rdd.length; i++) {
              if (rdd[i].split === "true") {
                rdd[i].rcd.sort((a, b) =>
                  parseInt(a.formView.pn) > parseInt(b.formView.pn)
                    ? 1
                    : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                    ? -1
                    : 0
                );
              }
            }
            rdd.sort((a, b) =>
              parseInt(a.formView.pn) > parseInt(b.formView.pn)
                ? 1
                : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                ? -1
                : 0
            );
            rcd.sort((a, b) =>
              parseInt(a.index) > parseInt(b.index)
                ? 1
                : parseInt(a.index) < parseInt(b.index)
                ? -1
                : 0
            );
            //  setRecord(rcd);

            setInfoRcdView([...rdd]);
            setShowModelInfo(!showModelInfo);
          },
          (error) => {
            // setLoading(false)
            let err = { message: error.message, code: error.response.status };
            navigation("/error", { state: { err: err } });
          }
        );
    } else {
      toast.error("No Record");
    }
  };

  const checkAccess = () => {
    let tabname = searchParam.get("tableName");
    let listCheck = listAccess.current.find((e) => e === tabname);
    let newCheck = newAccess.current.find((e) => e === tabname);
    if (newCheck) {
      tableName.current = tabname;
      rty.current = searchParam.get("rty");
      if (rty.current === "new") {
        return true;
      } else if (rty.current === "record" && listCheck && newCheck) {
        rid.current = searchParam.get("rid");
        return true;
      }
    } else if (listCheck) {
      rty.current = searchParam.get("rty");
      if (rty.current === "record" && listCheck) {
        tableName.current = tabname;

        rid.current = searchParam.get("rid");
        return true;
      }
    }
    return false;
  };

  const getScriptList = () => {
    let isAccessable = checkAccess();
    if (isAccessable) {
      axios
        .get(loca + "/marketplace/get/script", {
          headers: {
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then(
          (resp) => {
            let res = resp.data;
            setReScript(res.reusableScript);
            getFormDetails();
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      setLoading(false);
      toast(
        "Incorrect search query or you don't have permission to access this",
        { pauseOnHover: true, theme: "colored", type: "error" }
      );
    }
  };

  const setRecordDatas = (record) => {
    setButton(record[3].button);
    setTabLabel(record[1].table.label);
    setCid(record[7].client.cid);
    setActivity(record[8]?.activity);
    return record[2].record;
  };

  const getFormDetails = () => {
    setLoading(true);
    setCol_mn([]);
    setError("");
    setPageError(false);
    setMessage("");
    setPageMessage(false);
    setDownRcdView([]);
    if (rty.current === "new") {
      axios
        .get(loca + `/marketplace/get/blankrecord/` + tableName.current, {
          headers: {
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then(
          (resp) => {
            const blkrecord = resp.data;
            if ("Error" in blkrecord) {
              navigation("/dashboard", { state: { error: blkrecord.Error } });
            } else {
              setMainRecord(blkrecord);
              if ("appRecord" in blkrecord) {
                setCopyRcd(blkrecord.appRecord[2].record);
                setRecord(blkrecord.appRecord[2].record);
                setButton(blkrecord.appRecord[3].button);
                setTabLabel(blkrecord.appRecord[1].table.label);
                setTabId(blkrecord.appRecord[1].table.id);
              } else if ("columnRecord" in blkrecord) {
                setCopyRcd(blkrecord.columnRecord[2].record);
                setRecord(blkrecord.columnRecord[2].record);
                setButton(blkrecord.columnRecord[3].button);
                setTabLabel(blkrecord.columnRecord[1].table.label);
                setTabId(blkrecord.columnRecord[1].table.id);
              } else if ("formRecord" in blkrecord) {
                setCopyRcd(blkrecord.formRecord[2].record);
                setRecord(blkrecord.formRecord[2].record);
                setButton(blkrecord.formRecord[3].button);
                setTabLabel(blkrecord.formRecord[1].table.label);
                setTabId(blkrecord.formRecord[1].table.id);
              } else if ("choiceRecord" in blkrecord) {
                setCopyRcd(blkrecord.choiceRecord[2].record);
                setRecord(blkrecord.choiceRecord[2].record);
                setButton(blkrecord.choiceRecord[3].button);
                setTabLabel(blkrecord.choiceRecord[1].table.label);
                setTabId(blkrecord.choiceRecord[1].table.id);
              } else if ("labelRecord" in blkrecord) {
                setCopyRcd(blkrecord.labelRecord[2].record);
                setRecord(blkrecord.labelRecord[2].record);
                setButton(blkrecord.labelRecord[3].button);
                setTabLabel(blkrecord.labelRecord[1].table.label);
                setTabId(blkrecord.labelRecord[1].table.id);
              } else if ("buttonRecord" in blkrecord) {
                setCopyRcd(blkrecord.buttonRecord[2].record);
                setRecord(blkrecord.buttonRecord[2].record);
                setButton(blkrecord.buttonRecord[3].button);
                setTabLabel(blkrecord.buttonRecord[1].table.label);
                setTabId(blkrecord.buttonRecord[1].table.id);
              } else if ("tableRecord" in blkrecord) {
                setCopyRcd(blkrecord.tableRecord[2].record);
                setRecord(blkrecord.tableRecord[2].record);
                setButton(blkrecord.tableRecord[3].button);
                setTabLabel(blkrecord.tableRecord[1].table.label);
                setTabId(blkrecord.tableRecord[1].table.id);
              } else if ("typeRecord" in blkrecord) {
                setCopyRcd(blkrecord.typeRecord[2].record);
                setRecord(blkrecord.typeRecord[2].record);
                setButton(blkrecord.typeRecord[3].button);
                setTabLabel(blkrecord.typeRecord[1].table.label);
                setTabId(blkrecord.typeRecord[1].table.id);
              } else if ("uivalidationRecord" in blkrecord) {
                setCopyRcd(blkrecord.uivalidationRecord[2].record);
                setRecord(blkrecord.uivalidationRecord[2].record);
                setButton(blkrecord.uivalidationRecord[3].button);
                setTabLabel(blkrecord.uivalidationRecord[1].table.label);
                setTabId(blkrecord.uivalidationRecord[1].table.id);
              } else if ("languageRecord" in blkrecord) {
                setCopyRcd(blkrecord.languageRecord[2].record);
                setRecord(blkrecord.languageRecord[2].record);
                setButton(blkrecord.languageRecord[3].button);
                setTabLabel(blkrecord.languageRecord[1].table.label);
                setTabId(blkrecord.languageRecord[1].table.id);
              } else if ("userRecord" in blkrecord) {
                setCopyRcd(blkrecord.userRecord[2].record);
                setRecord(blkrecord.userRecord[2].record);
                setButton(blkrecord.userRecord[3].button);
                setTabLabel(blkrecord.userRecord[1].table.label);
                setTabId(blkrecord.userRecord[1].table.id);
              } else {
              }

              let script = null;
              let policy = [];
              let rcd = [];
              if ("formRecord" in blkrecord) {
                script = blkrecord.formRecord[4].uiscript;
                rcd = blkrecord.formRecord[2].record;
                policy = blkrecord.formRecord[5].uipolicy;
                setCid(blkrecord.formRecord[6].client.cid);
              } else if ("appRecord" in blkrecord) {
                script = blkrecord.appRecord[4].uiscript;
                policy = blkrecord.appRecord[5].uipolicy;
                rcd = blkrecord.appRecord[2].record;
                setCid(blkrecord.appRecord[6].client.cid);
              } else if ("tableRecord" in blkrecord) {
                script = blkrecord.tableRecord[4].uiscript;
                policy = blkrecord.tableRecord[5].uipolicy;
                rcd = blkrecord.tableRecord[2].record;
                setCid(blkrecord.tableRecord[6].client.cid);
              } else if ("typeRecord" in blkrecord) {
                script = blkrecord.typeRecord[4].uiscript;
                policy = blkrecord.typeRecord[5].uipolicy;
                rcd = blkrecord.typeRecord[2].record;
                setCid(blkrecord.typeRecord[6].client.cid);
              } else if ("uivalidationRecord" in blkrecord) {
                script = blkrecord.uivalidationRecord[4].uiscript;
                policy = blkrecord.uivalidationRecord[5].uipolicy;
                rcd = blkrecord.uivalidationRecord[2].record;
                setCid(blkrecord.uivalidationRecord[6].client.cid);
              } else if ("buttonRecord" in blkrecord) {
                script = blkrecord.buttonRecord[4].uiscript;
                policy = blkrecord.buttonRecord[5].uipolicy;
                rcd = blkrecord.buttonRecord[2].record;
                setCid(blkrecord.buttonRecord[6].client.cid);
              } else if ("choiceRecord" in blkrecord) {
                script = blkrecord.choiceRecord[4].uiscript;
                rcd = blkrecord.choiceRecord[2].record;
                policy = blkrecord.choiceRecord[5].uipolicy;
                setCid(blkrecord.choiceRecord[6].client.cid);
              } else if ("languageRecord" in blkrecord) {
                script = blkrecord.languageRecord[4].uiscript;
                rcd = blkrecord.languageRecord[2].record;
                policy = blkrecord.languageRecord[5].uipolicy;
                setCid(blkrecord.languageRecord[6].client.cid);
              } else if ("columnRecord" in blkrecord) {
                script = blkrecord.columnRecord[4].uiscript;
                rcd = blkrecord.columnRecord[2].record;
                policy = blkrecord.columnRecord[5].uipolicy;
                setCid(blkrecord.columnRecord[6].client.cid);
              } else if ("labelRecord" in blkrecord) {
                script = blkrecord.labelRecord[4].uiscript;
                rcd = blkrecord.labelRecord[2].record;
                policy = blkrecord.labelRecord[5].uipolicy;
                setCid(blkrecord.labelRecord[6].client.cid);
              } else if ("userRecord" in blkrecord) {
                script = blkrecord.userRecord[4].uiscript;
                policy = blkrecord.userRecord[5].uipolicy;
                rcd = blkrecord.userRecord[2].record;
                setCid(blkrecord.userRecord[6].client.cid);
              }
              // onload
              // let userDetails = JSON.parse(localStorage.getItem("userDetails"));
              if (script !== null) {
                for (let i = 0; i < script.length; i++) {
                  let func = script[i].script;
                  let type = script[i].type;
                  if (type === "onload" && count.current === 1) {
                    count.current = 0;
                    onLoad(
                      func,
                      new ApUser(userDetails, setRecord),
                      new ApForm(rcd, setRecord, tabRelation, setTabRelation)
                    );
                  }
                }
              }
              let StartIndex = 0;
              let mapObj = new Map();
              for (let i = 0; i < rcd.length; i++) {
                rcd[i].index = i;
                for (let k = 0; k < fieldName.length; k++) {
                  if (rcd[i].name === fieldName[k]) {
                    let ref = { id: 0, name: "" };
                    for (let j = 0; j < recordNew.length; j++) {
                      if (k > 0 && recordNew[j].name === fieldName[k]) {
                        ref = recordNew[j].value;
                      } else if (k === 0) {
                        if (recordNew[j].name === "id") {
                          ref.id = recordNew[j].value;
                        }
                        if (recordNew[j].displayColumn === "true") {
                          ref.name = recordNew[j].value;
                        }
                      }
                    }
                    rcd[i].value = ref;
                  }
                }
                if (rcd[i]?.secondary === "true" && StartIndex === 0) {
                  setSecondaryStartIndex(i);
                  setTimeout(() => {}, 5000);
                  StartIndex++;
                }
                if (rcd[i]?.secondary === "true") {
                  let fv = rcd[i]?.formView;
                  let col = fv.path.split(".")[1];
                  if (mapObj.get(col)) {
                    mapObj.get(col).push(fv);
                  } else {
                    let vl = [];
                    vl.push(fv);
                    mapObj.set(col, vl);
                  }
                }
              }

              setSecondarycallsCols(mapObj);

              rcd.sort((a, b) =>
                parseInt(a.formView.pn) > parseInt(b.formView.pn)
                  ? 1
                  : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                  ? -1
                  : 0
              );

              let rd = [];
              let rdd = [];
              let left_rd = [];
              let downRcd = [];
              let left_check = false;
              let cct = [];
              for (let i = 0; i < rcd.length; i++) {
                cct.push(rcd[i].type);
                if (rcd[i].type === "key_value") {
                  rcd[i].selected = false;
                  rcd[i].value = "";
                  // rcd[i].value = keyValueJson;
                }
                if (rcd[i].type === "group_key_value") {
                  if (rcd[i].value === "") {
                    rcd[i].value = groupkeyValue;
                  }
                }
                if (rcd[i].type === "activity") {
                  downRcd.push(rcd[i]);
                }
                if (rcd[i].formView.position === "left") {
                  // rcd[i].index = i;
                  // if (rcd[i].uivalid.visible=== "true") {
                  rd.push(rcd[i]);
                  // }
                  if (
                    i + 1 < rcd.length &&
                    rcd[i + 1].formView.position === "full"
                  ) {
                    rdd.push({
                      split: "true",
                      rcd: rd,
                      formView: {
                        co: rd[0].formView.co,
                        pn: rd[0].formView.pn,
                      },
                    });
                    left_rd = [];
                    left_check = true;
                    rd = [];
                  }
                } else if (rcd[i].formView.position === "right") {
                  rd.push(rcd[i]);
                  if (
                    i === rcd.length - 1 ||
                    rcd[i + 1].formView.position === "full"
                  ) {
                    rdd.push({
                      split: "true",
                      rcd: rd,
                      formView: {
                        co: rd[0].formView.co,
                        pn: rd[0].formView.pn,
                      },
                    });
                    rd = [];
                    left_check = false;
                    left_rd = [];
                  }
                } else {
                  if (left_check) {
                    rcd[i].split = "false";
                    rdd.push(rcd[i]);
                  } else {
                    rcd[i].split = "false";
                    rdd.push(rcd[i]);
                  }
                }
              }
              for (let i = 0; i < rdd.length; i++) {
                if (rdd[i].split === "true") {
                  rdd[i].rcd.sort((a, b) =>
                    parseInt(a.formView.pn) > parseInt(b.formView.pn)
                      ? 1
                      : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                      ? -1
                      : 0
                  );
                }
              }
              rdd.sort((a, b) =>
                parseInt(a.formView.pn) > parseInt(b.formView.pn)
                  ? 1
                  : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                  ? -1
                  : 0
              );
              rcd.sort((a, b) =>
                parseInt(a.index) > parseInt(b.index)
                  ? 1
                  : parseInt(a.index) < parseInt(b.index)
                  ? -1
                  : 0
              );
              setPolicyFilter(policy.filter);
              setPolicyAction(policy.policy_action);
              setMainRecord(blkrecord);

              uScript.current = script;
              setUiScript([...script]);
              rcrd.current = rcd;
              setCurrent_Col_Types(cct);
              setRecord(rcd);
              setRcdView(rdd);
              setDownRcdView(downRcd);
              setap_form(new ApForm(rcd, setRecord, setTabRelation));
              setap_user(new ApUser(userDetails, setRecord));
              checkRefrecord();
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else if (rty.current === "record") {
      axios
        .get(
          loca +
            `/marketplace/get/singlerecord/${tableName.current}/${rid.current}`,
          {
            headers: {
              // "Content-Type": "application/json",
              authorization: "Bearer " + token,
              "market-application": appname,
            },
          }
        )
        .then(
          (resp) => {
            const record = resp.data;
            var mmm = record.formRecord[2].record;
            setCopyRcd(mmm);
            let rValue = "";
            let ref_cols = [];
            for (var i = 0; i < mmm.length; i++) {
              if (mmm[i].type === "reference") {
                ref_cols.push(mmm[i]);
              }
              if (mmm[i].name === "name") {
                let jso = {};
                let id = 0;
                for (let tmp = 0; tmp < mmm.length; tmp++) {
                  if (mmm[tmp].name === "id") {
                    id = mmm[tmp].value;
                    break;
                  }
                }
                let fake_jso = JSON.parse(JSON.stringify(mmm[i]));
                fake_jso.name = tableName.current + "_id";
                jso.id = id;
                jso.name = mmm[i].value;
                fake_jso.value = jso;
                ref_cols.push(fake_jso);
              }
              if (mmm[i].value !== "") {
                mmm[i].verified = "verified";
              } else {
                mmm[i].verified = "initial";
              }
              if (mmm[i].type === "password") {
                mmm[i].value = "";
              }

              if (mmm[i].displayColumn === "true") {
                rValue = mmm[i].value;
              }
              if (mmm[i].type === "key_value") {
                mmm[i].hidden_val = JSON.parse(JSON.stringify(mmm[i].value));
                for (let j = 0; j < mmm.length; j++) {
                  if (
                    mmm[j].type === "reference" &&
                    mmm[j].col_types.includes("group_key_value") &&
                    mmm[j].refTable.value === mmm[i].for_table
                  ) {
                    mmm[i].value = mmm[j].value.name;
                    mmm[i].selected = true;
                    mmm[i].uivalid.mandatory = false;
                    break;
                  }
                }
              }
            }
            refValue.current = rValue;
            let script = null;
            let rcd = [];
            if ("Error" in record) {
              navigation("/dashboard", { state: { error: record.Error } });
            } else {
              setMainRecord(record);

              script = record.formRecord[6].uiscript;
              if ("appRecord" in record) {
                rcd = setRecordDatas(record.appRecord);
              } else if ("columnRecord" in record) {
                rcd = setRecordDatas(record.columnRecord);
              } else if ("formRecord" in record) {
                rcd = setRecordDatas(record.formRecord);
              } else if ("choiceRecord" in record) {
                rcd = setRecordDatas(record.choiceRecord);
              } else if ("labelRecord" in record) {
                rcd = setRecordDatas(record.labelRecord);
              } else if ("buttonRecord" in record) {
                rcd = setRecordDatas(record.buttonRecord);
              } else if ("tableRecord" in record) {
                rcd = setRecordDatas(record.tableRecord);
              } else if ("typeRecord" in record) {
                rcd = setRecordDatas(record.typeRecord);
              } else if ("uivalidationRecord" in record) {
                rcd = setRecordDatas(record.uivalidationRecord);
              } else if ("languageRecord" in record) {
                rcd = setRecordDatas(record.languageRecord);
              } else if ("userRecord" in record) {
                rcd = setRecordDatas(record.userRecord);
              }
            }
            if (script !== null) {
              for (let i = 0; i < script.length; i++) {
                let func = script[i].script;
                let type = script[i].type;
                if (type === "onload" && count.current === 1) {
                  // count.current = 0;
                  onLoad(
                    func,
                    new ApUser(userDetails, setRecord),
                    new ApForm(rcd, setRecord, tabRelation, setTabRelation)
                  );
                }
              }
            }
            i_d.current = mmm[0].value;
            let count1 = 0;
            let call_check = true;
            let StartIndex = 0;
            let mapObj = new Map();
            for (let i = 0; i < mmm.length; i++) {
              if (mmm[i].type === "filter" && mmm[i].value !== "") {
                var filter = mmm[i].value.filter;
                setDcFiltVal(filter[0].dc.label);
                setFiltArray(mmm[i].value);
                setTimeline(mmm[i].value.timeline);
                deftimeline.current = mmm[i].value.timeline;
                count1++;
              }
              if (mmm[i].type === "filter_script" && mmm[i].value !== "") {
                setFiltArray(mmm[i].value);
                count1++;
              }
              if (mmm[i].name === "market_table_id") {
                callFieldList(mmm[i].value.id, true);
                count1++;
              }
              if (mmm[i].type === "multi_select") {
                let parsVal = mmm[i].value.record;
                setMscList(parsVal);
                count1++;
              }
              if (count1 === 4) {
                break;
              }

              if (mmm[i]?.secondary === "true" && StartIndex === 0) {
                setSecondaryStartIndex(i);
                setTimeout(() => {}, 5000);
                StartIndex++;
              }
              if (mmm[i]?.secondary === "true") {
                let fv = mmm[i]?.formView;
                let col = fv.path.split(".")[1];
                if (mapObj.get(col)) {
                  mapObj.get(col).push(fv);
                } else {
                  let vl = [];
                  vl.push(fv);
                  mapObj.set(col, vl);
                }
              }
            }
            setSecondarycallsCols(mapObj);
            if (tableName.current === "reference_filter") {
              axios
                .get(loca + `/marketplace/get/refFilter/${rid.current}`, {
                  headers: {
                    // "Content-Type": "application/json",
                    authorization: "Bearer " + token,
                    "market-application": appname,
                  },
                })
                .then(
                  (resp) => {
                    const coldata = resp.data;
                    if (coldata !== "") {
                      if ("Error" in coldata) {
                        setLoading(false);
                        setPageError(true);
                        setError(coldata.Error);
                      } else {
                        var col_array = [];
                        col_array.push({ id: "", name: "none", label: "None" });
                        for (var c = 0; c < coldata.columnRecords.length; c++) {
                          col_array.push(coldata.columnRecords[c]);
                        }
                        var choicearry = [];
                        for (var z = 0; z < coldata.columnRecords.length; z++) {
                          choicearry.push({
                            id: coldata.columnRecords[z].id,
                            name: coldata.columnRecords[z].name,
                            label: coldata.columnRecords[z].label,
                            value: coldata.columnRecords[z].name,
                            choice_order: z * 100 + "",
                            form_column_id: "",
                          });
                        }
                        setColumn_depend(choicearry);
                      }
                    }
                  },
                  (error) => {
                    // setLoading(false)
                    let err = {
                      message: error.message,
                      code: error.response.status,
                    };
                    navigation("/error", { state: { err: err } });
                  }
                );
            }
            for (let i = 0; i < rcd.length; i++) {
              rcd[i].index = i;
            }
            rcd.sort((a, b) =>
              parseInt(a.formView.pn) > parseInt(b.formView.pn)
                ? 1
                : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                ? -1
                : 0
            );

            let rd = [];
            let rdd = [];
            let left_rd = [];
            let left_check = false;
            let downRcd = [];
            for (let i = 0; i < rcd.length; i++) {
              if (rcd[i].type === "activity") {
                downRcd.push(rcd[i]);
              }
              if (rcd[i].formView.position === "left") {
                // rcd[i].index = i;
                rd.push(rcd[i]);
                if (rcd[i + 1] && rcd[i + 1].formView.position === "full") {
                  left_check = true;
                }
              } else if (rcd[i].formView.position === "right") {
                // rcd[i].index = i;
                rd.push(rcd[i]);
                if (
                  i === rcd.length - 1 ||
                  rcd[i + 1].formView.position === "full"
                ) {
                  rdd.push({
                    split: "true",
                    rcd: rd,
                    formView: { co: rd[0].formView.co },
                  });
                  for (let j = 0; j < left_rd.length; j++) {
                    rdd.push(left_rd[j]);
                  }
                  rd = [];
                  left_check = false;
                  left_rd = [];
                }
              } else {
                if (left_check) {
                  rcd[i].split = "false";
                  // rcd[i].index = i;
                  left_rd.push(rcd[i]);
                } else {
                  rcd[i].split = "false";
                  // rcd[i].index = i;
                  rdd.push(rcd[i]);
                }
              }
              // console.log(rcd[i]);
            }
            for (let i = 0; i < rdd.length; i++) {
              if (rdd[i].split === "true") {
                rdd[i].rcd.sort((a, b) =>
                  parseInt(a.formView.pn) > parseInt(b.formView.pn)
                    ? 1
                    : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                    ? -1
                    : 0
                );
              }
            }

            rdd.sort((a, b) =>
              parseInt(a.formView.pn) > parseInt(b.formView.pn)
                ? 1
                : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                ? -1
                : 0
            );
            // rcd.sort((a,b)=>parseInt(a.beforeIndex)>parseInt(b.beforeIndex)?1:parseInt(a.beforeIndex)<parseInt(b.beforeIndex)?-1:0)
            rcd.sort((a, b) =>
              parseInt(a.index) > parseInt(b.index)
                ? 1
                : parseInt(a.index) < parseInt(b.index)
                ? -1
                : 0
            );
            setAll_ref_cols(ref_cols);
            setRecord(rcd);
            rcrd.current = rcd;
            setRcdView(rdd);
            setDownRcdView(downRcd);
            setUiScript(script);
            setMainRecord(record);
            setap_form(new ApForm(rcd, setRecord, tabRelation, setTabRelation));
            setap_user(new ApUser(userDetails, setRecord));
            uScript.current = script;
            if (call_check) {
              var chk = false;
              for (let i = 0; i < script.length; i++) {
                // let field = script[i].field.name;
                let func = script[i].script;
                let type = script[i].type;
                if (type === "onload") {
                  chk = true;
                  count.current = 1;
                }
              }
              if (!chk) {
                setLoading(false);
              }
              setLoading(false);
              relationVerify();
            } else {
              checkRefrecord();
            }
            // setTimeout(() => {}, 2000);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  const setRef = (id, val, properties, refTable) => {
    getSecondaryFields(objName, id, refTable);

    formChangefn(
      val,
      objIndex,
      objName,
      objType,
      id,
      true,
      "",
      "",
      "",
      "",
      properties
    );
    cancelModelList();
  };

  const setReference = (name) => {
    if (uiScript.length > 0) {
      for (let i = 0; i < uiScript.length; i++) {
        // let field = uiScript[i].field.name;
        let func = uiScript[i].script;
        let type = uiScript[i].type;
        if (type === "onreference" && ref_filter[0].co === "") {
          onReference(func, name);
        }
      }
    }
  };

  const cancelModelList = () => {
    // setFilt("null");
    setshowModelList(false);
  };

  const afterCellEdit = (vl, ob) => {
    for (let i = 0; i < uiScript.length; i++) {
      let field = uiScript[i].field.name;
      let func = uiScript[i].script;
      let type = uiScript[i].type;
      if (field === ob && type === "oncelledit") {
        onCellEdit(func, vl);
      }
    }
  };

  const checkRefrecord = () => {
    axios
      .get(loca + "/marketplace/get/reference/qualifier/" + tableName.current, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((resp) => {
        const rq_data = resp.data;
        if (rq_data !== "") {
          // this.setState({ record_rq: rq_data });
          let script = uScript.current;
          setrecord_rq(rq_data);
          // onload
          let userDetails = JSON.parse(localStorage.getItem("userDetails"));
          if (script.length > 0) {
            for (let i = 0; i < script.length; i++) {
              // let field = script[i].field.name;
              let func = script[i].script;
              let type = script[i].type;
              if (type === "onload" && count.current === 1) {
                count.current = 0;
                onLoad(
                  func,
                  new ApUser(userDetails, setRecord),
                  new ApForm(
                    rcrd.current,
                    setRecord,
                    tabRe.current,
                    setTabRelation
                  )
                );
              } else {
                setTabRel(true);
              }
            }
            // console.log(count.current);
            // if (count.current===1) {
            // setTimeout(() => {
            setLoading(false);
            // }, 1000);
            // }
          } else {
            // setTimeout(() => {
            setLoading(false);
            setTabRel(true);
            // }, 1000);
          }
        }
      });
  };

  const relationVerify = () => {
    // var token = localStorage.getItem("token");
    // this.setState({ relation_loading: true });
    let rid_sp = searchParam.get("rid");
    let mt_sp = searchParam.get("tableName");
    let rt_ls = JSON.parse(localStorage.getItem("relatedTables"));
    let rid_ls = localStorage.getItem("relatedTablesForRid");
    let mt_ls = localStorage.getItem("relatedTablesForMT");
    if (rid_ls === rid_sp && mt_sp === mt_ls) {
      checkRefrecord();
      setTabRelation(rt_ls);
      tabRe.current = rt_ls;
    } else {
      let rvalue = refValue.current;
      var json = {
        relation: [
          {
            tabname: tableName.current,
            recordid: rid.current,
            value: rvalue,
          },
        ],
      };
      // setRelationload(true);
      setTabRel(false);
      axios
        .post(loca + "/marketplace/get/relation", json, {
          headers: {
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then(
          (resp) => {
            const rvrecord = resp.data;
            if ("Error" in rvrecord) {
              setLoading(false);
              setPageError(true);
              setError(rvrecord.error);
            }
            checkRefrecord();
            if (rvrecord.relation.length === 0) {
              setLoading(false);
              setTabRelation(rvrecord);
              tabRe.current = rvrecord;
            } else if ("Error" in rvrecord.relation[0]) {
              setLoading(false);
            } else {
              setTabRelation(rvrecord);
              tabRe.current = rvrecord;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  const onChange = (func, val, obj) => {
    let fn = new Function(["ap_user", "ap_form", "val", "obj"], func);
    fn(ap_user, ap_form, val, obj);
  };

  const onCellEdit = (func, val) => {
    let fn = new Function(["ap_user", "ap_form", "val"], func);
    fn(ap_user, ap_form, val);
  };

  const onSubmit = (func) => {
    let fn = new Function(["ap_user", "ap_form"], func);
    fn(ap_user, ap_form);
  };

  const onLoad = (func, user, form) => {
    let fn = new Function(["ap_user", "ap_form"], func);
    fn(user, form);
    setTimeout(() => {
      setLoading(false);
      setTabRel(true);
    }, 500);
  };

  const onReference = (func) => {
    let fn = new Function(["ap_user", "ap_form"], func);
    let result = fn(ap_user, ap_form);
    // let result=ap_form.getFieldValue('form_table_id').name
    if (result !== undefined) {
      setFilt(result);
      setref_filter([result]);
    }
  };

  const callfilter = async (filtarray, index, in_index, col_id) => {
    var rcd = record;
    if (col_id !== -1) {
      if (
        (rcd[index].type === "filter" || rcd[index].type === "filter_script") &&
        filtarray[in_index].ct === "choice" &&
        filtarray[in_index].ch.length === 0
      ) {
        let chc = await getChoiceRcd(col_id).then((res) => {
          if (res.length > 0) {
            filtarray[in_index].ch = res;
            filtarray[in_index].an = res[0].name;
          } else {
            filtarray[in_index].ch = [];
          }
        });
      }
      // }
    }
    rcd[index].value.filter = filtarray;
    setRecord([...rcd]);
    // setFiltArray([...filtarray]);
  };

  const calltimeline = (timeline, index) => {
    var rcd = record;
    deftimeline.current = timeline;
    if (index != undefined && index != null) {
      rcd[index].value.timeline = timeline;
      setRecord([...rcd]);
    }
  };

  const callScript = (script, index) => {
    var rcd = record;
    rcd[index].value.script = script;
    setRecord([...rcd]);
  };

  const getChoiceRcd = async (col_id) => {
    let chk = [];
    let ck = await axios
      .get(loca + "/marketplace/get/choice/" + col_id, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          let chk = res.choiceRecords;
          return chk;
        },
        (error) => {
          console.log(error);
        }
      );
    return ck;
  };

  const goToColumn = () => {
    checkMenu.current = true;
    setTabRel(false);
    searchParam.set("rty", "record");
    searchParam.set("tableName", "market_column");
    searchParam.set("rid", m_rid.current);
    setSearchParam(searchParam);
    // getRecordForm(m_rid.current, "market_column");
  };

  const goToLabel = () => {
    checkMenu.current = true;
    axios
      .get(loca + "/marketplace/get/record/label/" + m_rid.current, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then(
        (resp) => {
          let lab_id = resp.data;
          if (
            lab_id !== null ||
            lab_id !== "" ||
            lab_id !== "null" ||
            lab_id !== undefined
          ) {
            searchParam.set("rty", "record");
            searchParam.set("tableName", "label");
            searchParam.set("rid", lab_id);
            setSearchParam(searchParam);
            // getRecordForm(lab_id, "label");
          }
        },
        (error) => {
          toast("Some thing went wrong", { type: "error" });
        }
      );
  };

  const goTouivalid = () => {
    checkMenu.current = true;

    axios
      .get(
        loca +
          "/marketplace/get/singlerecord/uivalid/" +
          m_rid.current +
          "/" +
          searchParam.get("rty"),
        {
          headers: {
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        }
      )
      .then(
        (resp) => {
          let lab_id = resp.data;
          if (
            lab_id !== null ||
            lab_id !== "" ||
            lab_id !== "null" ||
            lab_id !== undefined
          ) {
            searchParam.set("rty", "record");
            searchParam.set("tableName", "uivalidation");
            searchParam.set("rid", lab_id);
            setSearchParam(searchParam);
            // getRecordForm(lab_id, "form_label");
          }
        },
        (error) => {
          toast("Some thing went wrong", { type: "error" });
        }
      );
  };

  useEffect(() => {
    count.current = 1;
    // setRelationload(true);
    if (location) {
      recordNew = location.recordNew;
      fieldName = location.fieldName;
    }
    document
      .getElementsByTagName("body")
      .item(0)
      .addEventListener("mousedown", () => {
        setShowContextMenu(false);
        setShowContext(false);
      });
    //    setSearchParam(searchParam)
    let p = user + "/form";
    if (pathName === p) {
      getScriptList();
    }
  }, [searchParam]);

  const multipart = useRef(new FormData()).current;
  const [imgObj, setImgObj] = useState([]);

  const launchChoose = (id) => {
    let l = document.getElementById(id);
    l.click();
  };
  const handleFileChangeForVideo = (event, objj) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      const fileSize = selectedFile.size;
      const maxSizeInKb = Number(objj.uivalid.max_length) / 10;
      if (+(fileSize / 1024).toFixed(2) <= maxSizeInKb) {
        const selectedFile = event.target.files[0];
        let uarr = "images/" + selectedFile.name;
        const imageURL = URL.createObjectURL(selectedFile);
        let tmp = [];
        if (imgObj) {
          tmp = imgObj;
        }
        tmp.push(uarr);
        setImgObj([...tmp]);
        let frecord = record;
        frecord[objj.index].value = imageURL;
        frecord[objj.index].localvid = true;
        setRecord([...frecord]);
        formChangefn(
          imageURL,
          objj.index,
          objj.name,
          objj.type,
          objj.id,
          false
        );
        multipart.set("path", null);
        objj.fdata = event.target.files[0];
        // multipart.append("files", event.target.files[0]);
        setPageError(false);
        setError("");
      } else {
        setError("File size exceeds the limit");
        setPageError(true);
      }
    }
  };

  const handleFileChange = (event, objj) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      const fileSize = selectedFile.size;
      const maxSizeInKb = Number(objj.uivalid.max_length);
      if (+(fileSize / 1024).toFixed(2) <= maxSizeInKb) {
        const selectedFile = event.target.files[0];
        let uarr = "images/" + selectedFile.name;
        const imageURL = URL.createObjectURL(selectedFile);
        let tmp = [];
        if (imgObj) {
          tmp = imgObj;
        }
        tmp.push(uarr);
        setImgObj([...tmp]);
        let frecord = record;
        frecord[objj.index].value = imageURL;
        frecord[objj.index].localimg = true;
        setRecord([...frecord]);
        formChangefn(
          imageURL,
          objj.index,
          objj.name,
          objj.type,
          objj.id,
          false
        );
        multipart.set("path", null);
        objj.fdata = event.target.files[0];
        setPageError(false);
        setError("");
      } else {
        setError("File size exceeds the limit");
        setPageError(true);
      }
    }
  };

  const handlePdfFileChange = (event, objj) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      const fileSize = selectedFile.size;
      const fileName = selectedFile.name;

      const maxSizeInKb = Number(objj.uivalid.max_length);
      if (+(fileSize / 1024).toFixed(2) <= maxSizeInKb) {
        const selectedFile = event.target.files[0];
        let frecord = record;
        frecord[objj.index].value = fileName;
        setRecord([...frecord]);
        formChangefn(
          fileName,
          objj.index,
          objj.name,
          objj.type,
          objj.id,
          false
        );
        multipart.set("path", null);
        objj.fdata = selectedFile;
        setPageError(false);
        setError("");
      } else {
        setError("File size exceeds the limit");
        setPageError(true);
      }
    }
  };

  // const pdfDownload = (url) => {
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.click();
  // };

  const pdfDownload = (json) => {
    // setLabelData(json)
    navigation("/invoice", { state: { data: json } });
  };

  const uploadImage = (rcd, ind, path, compress) => {
    return new Promise((resolve, reject) => {
      multipart.set("userId", userDetails.id);
      multipart.set("path", path);
      multipart.set("compress", compress);
      multipart.append("files", rcd.fdata);
      console.log("called image upload");

      if (multipart.has("files")) {
        axios
          .post(imageloca + `upload/images`, multipart, {
            headers: {
              Authorization: "Bearer " + token,
              "market-application": appname,
            },
          })
          .then(
            (res) => {
              let resp = res.data;
              if (resp !== "") {
                var imgs = resp;
                multipart.delete("files");
                resolve(imgs);
              } else {
                reject("Directory not found");
              }
            },
            (error) => {
              reject(error);
            }
          );
      }
    });
  };
  const uploadPdf = (rcd, path) => {
    return new Promise((resolve, reject) => {
      multipart.set("path", path);
      multipart.append("file", rcd.fdata);
      if (multipart.has("file")) {
        axios
          .post(imageloca + `upload-pdf`, multipart, {
            headers: {
              "market-application": appname,
            },
          })
          .then(
            (res) => {
              let resp = res.data;
              if (resp !== "error") {
                var imgs = resp;
                multipart.delete("file");
                resolve(imgs);
              } else {
                reject("Directory not found");
              }
            },
            (error) => {
              reject(error);
            }
          );
        }
    });
  };

  const uploadVideo = (rcd, ind, path) => {
    return new Promise((resolve, reject) => {
      multipart.set("userId", userDetails.id);
      multipart.set("path", path);
      multipart.append("files", rcd.fdata);
      if (multipart.has("files")) {
        axios
          .post(imageloca + `upload/videos/`, multipart, {
            headers: {
              Authorization: "Bearer " + token,
              "market-application": appname,
            },
          })
          .then(
            (res) => {
              let resp = res.data;
              var imgs = "";
              if (path) {
                imgs = "images/" + path + "/" + resp[0];
              } else {
                imgs = "images/" + resp[0];
              }
              // rcd.value = imgs;
              // rcdRef.current = imgs;
              multipart.delete("files");
              resolve(imgs);
              // return imgs;
            },
            (error) => {
              reject(error);
            }
          );
      }
    });
  };

  const refresh = () => {
    getScriptList();
    checkMenu.current = true;
    count.current = 1;
    // setRelationload(true);
    setChoice_mn([]);
    setLoading(true);
  };

  const back = () => {
    navigation(-1);
    // customNavigate(-1,"",formIsDirty)

    // setLoading(true);
  };

  const copy = () => {
    localStorage.setItem("rcdJson", JSON.stringify(copyRcd));
    localStorage.setItem("tableName", tableName.current);
    let rcdJson = localStorage.getItem("rcdJson");
  };

  const paste = () => {
    setLoading(true);
    let rcdJson = localStorage.getItem("rcdJson");
    let tab = localStorage.getItem("tableName");
    let tabId = "";
    let rdJson = JSON.parse(rcdJson);
    let obj = "";
    if (tab === tableName.current) {
      var rcd = record;
      for (let i = 0; i < rdJson.length; i++) {
        for (let j = 0; j < rcd.length; j++) {
          if (rcd[j].name === rdJson[i].name) {
            if (rdJson[i].type === "key_value") {
              rcd[j].value = rdJson[i].value;
              rcd[j].selected = rdJson[i].selected;
              rcd[j].gkv = rdJson[i].gkv;
              rcd[j].hidden_val = rdJson[i].hidden_val;
              rcd[j].uivalid.mandatory = rdJson[i].uivalid.mandatory;
            } else if (rdJson[i].name === "market_table_id") {
              tabId = rcd[j].value.id;
              if (tabId > 0) {
                callFieldList(tabId);
              }
            } else {
              rcd[j].value = rdJson[i].value;
            }

            for (let k = 0; k < uiScript.length; k++) {
              let field = uiScript[k].field.name;
              let func = uiScript[k].script;
              let type = uiScript[k].type;
              if (field === rdJson[i].name && type === "onchange") {
                onChange(func, rdJson[i].value, obj);
              }
            }
          }
        }
      }
      // setRecord(rdJson);
    }
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const menuFn = (body) => {
    let fn = new Function(
      [
        "back",
        "refresh",
        "copy",
        "paste",
        "navigation",
        "tableName",
        "goToColumn",
        "goToLabel",
        "goTouivalid",
        "user",
      ],
      body
    );
    fn(
      back,
      refresh,
      copy,
      paste,
      navigation,
      tableName.current,
      goToColumn,
      goToLabel,
      goTouivalid,
      user
    );
  };

  const deleteImage = (objj) => {
    objj.localimg = true;
    formChangefn("", objj.index, objj.name, objj.type, objj.id, false);
    multipart.delete("files");
  };

  useEffect(() => {
    if (objdata) {
      let ar = [];
      ar.push(formData);
      formChangefn(
        ar,
        objdata.index,
        objdata.name,
        objdata.type,
        objdata.id,
        false
      );
    }
  }, [formData, objdata]);

  const initialJsonData = {
    properties: [
      {
        name: "size",
        choice: [{ value: "" }],
      },
      { name: "color", choice: [{ value: "" }] },
    ],
  };

  const [jsonData, setJsonData] = useState(initialJsonData);

  const handleInputChange = (propertyIndex, valueIndex, newValue) => {
    setJsonData((prevData) => ({
      ...prevData,
      properties: prevData.properties.map((prop, index) => {
        if (index === propertyIndex) {
          const updatedChoice = [...prop.choice];
          updatedChoice[valueIndex].value = newValue;
          return { ...prop, choice: updatedChoice };
        }
        return prop;
      }),
    }));
  };

  const handleAddChoice = (propertyIndex) => {
    setJsonData((prevData) => ({
      ...prevData,
      properties: prevData.properties.map((prop, index) => {
        if (index === propertyIndex) {
          return {
            ...prop,
            choice: [...prop.choice, { value: "" }],
          };
        }
        return prop;
      }),
    }));
  };

  const sendJsonToServer = () => {
    // Add your logic to send jsonData to the server
    console.log("Sending JSON:", jsonData);
  };

  /// close New Function ///

  const callTableColumn = (val, ot) => {
    if (val !== "" && val !== "none") {
      setCol_mn([]);
      axios
        .get(loca + "/marketplace/get/column/" + val, {
          headers: {
            authorization: "Bearer " + token,
            "market-application": appname,
          },
        })
        .then((resp) => {
          const coldata = resp.data;
          if (coldata !== "") {
            if ("Error" in coldata) {
              setLoading(false);
              setPageError(true);
              setError(coldata.Error);
            } else {
              var col_array = [];
              col_array.push({ id: "", name: "none", label: "None" });
              for (var c = 0; c < coldata.columnRecords.length; c++) {
                col_array.push(coldata.columnRecords[c]);
              }
              var choicearry = [];
              for (var z = 0; z < coldata.columnRecords.length; z++) {
                choicearry.push({
                  id: coldata.columnRecords[z].id,
                  name: coldata.columnRecords[z].name,
                  label: coldata.columnRecords[z].label,
                  value: coldata.columnRecords[z].name,
                  choice_order: z * 100 + "",
                  form_column_id: "",
                });
              }
              if (ot === true) {
                setChoice_mn(choicearry);
                // setColumn_other(col_array);
                // setColState(true);
              } else {
                setCol_mn(col_array);
                setChoice_mn(choicearry);
                // setColState(true);
                setLoading(false);
              }
            }
          }
        });
    } else {
      setCol_mn([]);
    }
  };
  const formNavHeight = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonValue, setButtonValue] = useState("");

  const openModal = (value) => {
    setButtonValue(value);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setButtonValue("");
  };

  const handleButtonClick = (value) => {
    if (value === "Delete") {
      openModal(value);
    } else {
      callbtn(value);
    }
  };

  const handleModalYes = () => {
    callbtn(buttonValue);
    closeModal();
  };

  const handleModalNo = () => {
    closeModal();
  };

  // let blocker = useBlocker(shouldBlock);

  let blocker = useBlocker(() => {
    return shouldBlock;
  });
  useEffect(() => {
    let h = document
      .getElementById("form" + tableName.current)
      ?.getBoundingClientRect().height;
    if (h > 10) {
      setOverlayH(h);
    }
  }, [loading]);
  if (!loading) {
    return (
      <div
        id={"form" + tableName.current}
        className="pagesetup"
        style={{ width: "100%" }}
      >
        {submitLoading && (
          <div style={{ height: overlayH }} className="loading-overlay"></div>
        )}
        {showContextMenu && (
          <ContextMenu
            menuX={menuX}
            menuY={menuY}
            contextMenu={labelContext}
            menuFn={menuFn}
            conditionFn={labelContext[0].cid === cid}
          />
        )}
        {showContext && (
          <ContextMenu
            menuX={menuX}
            menuY={menuY}
            contextMenu={contextMenu}
            menuFn={menuFn}
            excluded_menu={["import_data", "export_data", "open_in_new_tab"]}
            conditionFn={true}
          />
        )}
        <div
          ref={formNavHeight}
          className="btndiv"
          onContextMenu={(e) => {
            e.preventDefault();
            setContext(e.button, e.nativeEvent.pageX, e.nativeEvent.pageY);
          }}
        >
          <div
            className=" d-flex fr"
            style={{ justifyContent: "space-between" }}
          >
            <div
              // className="col-3 col-md-2 ms-3"
              style={{ alignSelf: "center", textAlign: "left" }}
            >
              <span>
                <i
                  style={{ marginLeft: "8px" }}
                  className=" backic fa fa-arrow-left bck_btt_mrg"
                  onClick={() => {
                    navigation(-1);
                  }}
                ></i>
              </span>
            </div>
            <div className="tab_head">{tablabel}</div>
            <div className="">
              <div className="btndivin">
                {button.map((obj, oo_i) => (
                  <button
                    className="insrtbtn2 btn btn-primary btn-sm"
                    key={oo_i}
                    onClick={() => handleButtonClick(obj.value)}
                    disabled={isClick}
                    style={{ backgroundColor: isClick ? "gray" : "" }}
                  >
                    {obj.value}
                  </button>
                ))}

                <Modal show={isModalOpen} onHide={handleModalNo}>
                  <Modal.Header
                    className="pb-0 border-0"
                    closeButton
                  ></Modal.Header>
                  <Modal.Body>
                    <h5>Are you sure you want to delete this record?</h5>
                  </Modal.Body>
                  <Modal.Footer className="border-0 py-0">
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={handleModalNo}
                    >
                      No
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={handleModalYes}
                    >
                      Yes
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
        <div className=" maincompo " style={{ height: formHeight }}>
          {page_error === true && (
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                padding: "0.2rem 0.2rem",
                marginBottom: "0px",
              }}
            >
              {error}
            </div>
          )}
          {page_message === true && (
            <div
              className="alert alert-success"
              role="alert"
              style={{
                padding: "0.2rem 0.2rem",
                marginBottom: "0px",
              }}
            >
              {message}
            </div>
          )}
          {record.length === 0 && <div>Dont have response...</div>}
          {record.length > 0 && (
            <div style={{ textAlign: "start", position: "relative" }}>
              <div>
                {rcdView.map((obj, index) => (
                  <div key={index}>
                    {obj.split === "false" &&
                      obj.uivalid.visible === "true" && (
                        <span key={index}>
                          <FormInnerCompo
                            userDetails={userDetails}
                            mscList={mscList}
                            setMSC={setMSC}
                            obj={obj}
                            m_rid={m_rid}
                            setContextMenu={setContextMenu}
                            formChangefn={formChangefn}
                            validationfn={validationfn}
                            passwordReadOnly={passwordReadOnly}
                            tableName={tableName.current}
                            selection={selection}
                            setShowSuggestion={setShowSuggestion}
                            getCaretPos={getCaretPos}
                            setY={setY}
                            setX={setX}
                            recordSuggestion={recordSuggestion}
                            userRecordSuggestion={userRecordSuggestion}
                            setSearchrcd={setSearchrcd}
                            searchModule={searchModule}
                            afterCellEdit={afterCellEdit}
                            x={x}
                            y={y}
                            showSuggestion={showSuggestion}
                            searchrcd={searchrcd}
                            ListGroup={ListGroup}
                            listRef={listRef}
                            onSelection={onSelection}
                            clickRefrence={clickRefrence}
                            getSingleInfo={getSingleInfo}
                            refrecord={refrecord}
                            showContainer={showContainer}
                            setRefRcd={setRefRcd}
                            showlist={showlist}
                            col_mn={col_mn}
                            callfilter={callfilter}
                            setFiltArray={setFiltArray}
                            timeline={timeline}
                            calltimeline={calltimeline}
                            callScript={callScript}
                            reScript={reScript}
                            JoditEditor={JoditEditor}
                            editor={editor}
                            choice_mn={choice_mn}
                            handleFileChange={handleFileChange}
                            handleFileChangeForVideo={handleFileChangeForVideo}
                            uploadImage={uploadImage}
                            uploadVideo={uploadVideo}
                            setImgObj={setImgObj}
                            imgObj={imgObj}
                            deleteImage={deleteImage}
                            multipart={multipart}
                            launchChoose={launchChoose}
                            formData={formData}
                            setFormData={setFormData}
                            objdata={objdata}
                            setObjData={setObjData}
                            jsonData={jsonData}
                            initialJsonData={initialJsonData}
                            setJsonData={setJsonData}
                            handleInputChange={handleInputChange}
                            handleAddChoice={handleAddChoice}
                            sendJsonToServer={sendJsonToServer}
                            keyValueJson={keyValueJson}
                            setKeyValueJson={setKeyValueJson}
                            groupkeyValue={groupkeyValue}
                            setGroupKeyValue={setGroupKeyValue}
                            sendProps={sendProps}
                            setSendProps={setSendProps}
                            imageloca={imageloca}
                            verifyError={verifyError}
                            current_Col_Types={current_Col_Types}
                            col_depend={column_depend}
                            dcFiltVal={dcFiltVal}
                            deftimeline={deftimeline}
                            setRecord={setRecord}
                            isClick={isClick}
                            page_error={page_error}
                            handlePdfFileChange={handlePdfFileChange}
                            record={record}
                          ></FormInnerCompo>
                        </span>
                      )}
                    {obj.split === "true" && (
                      <div className={isMobile ? "" : "row"}>
                        <div className="col">
                          {obj.rcd.map((obj_i, ind_i) => (
                            <div key={ind_i}>
                              {obj_i.formView.position === "left" &&
                                obj_i.uivalid.visible === "true" && (
                                  <span>
                                    <FormInnerCompo
                                      col_depend={column_depend}
                                      obj={obj_i}
                                      m_rid={m_rid}
                                      setContextMenu={setContextMenu}
                                      formChangefn={formChangefn}
                                      setMSC={setMSC}
                                      validationfn={validationfn}
                                      passwordReadOnly={passwordReadOnly}
                                      tableName={tableName.current}
                                      selection={selection}
                                      setShowSuggestion={setShowSuggestion}
                                      getCaretPos={getCaretPos}
                                      setY={setY}
                                      setX={setX}
                                      recordSuggestion={recordSuggestion}
                                      userRecordSuggestion={
                                        userRecordSuggestion
                                      }
                                      setSearchrcd={setSearchrcd}
                                      searchModule={searchModule}
                                      afterCellEdit={afterCellEdit}
                                      x={x}
                                      y={y}
                                      showSuggestion={showSuggestion}
                                      searchrcd={searchrcd}
                                      ListGroup={ListGroup}
                                      listRef={listRef}
                                      onSelection={onSelection}
                                      clickRefrence={clickRefrence}
                                      getSingleInfo={getSingleInfo}
                                      refrecord={refrecord}
                                      showContainer={showContainer}
                                      setRefRcd={setRefRcd}
                                      showlist={showlist}
                                      col_mn={col_mn}
                                      callfilter={callfilter}
                                      setFiltArray={setFiltArray}
                                      timeline={timeline}
                                      calltimeline={calltimeline}
                                      callScript={callScript}
                                      reScript={reScript}
                                      JoditEditor={JoditEditor}
                                      editor={editor}
                                      choice_mn={choice_mn}
                                      keyValueJson={keyValueJson}
                                      setKeyValueJson={setKeyValueJson}
                                      groupkeyValue={groupkeyValue}
                                      setGroupKeyValue={setGroupKeyValue}
                                      deleteImage={deleteImage}
                                      launchChoose={launchChoose}
                                      handleFileChange={handleFileChange}
                                      handleFileChangeForVideo={
                                        handleFileChangeForVideo
                                      }
                                      verifyError={verifyError}
                                      current_Col_Types={current_Col_Types}
                                      dcFiltVal={dcFiltVal}
                                      deftimeline={deftimeline}
                                      setRecord={setRecord}
                                      isClick={isClick}
                                      page_error={page_error}
                                      handlePdfFileChange={handlePdfFileChange}
                                      record={record}
                                    ></FormInnerCompo>
                                  </span>
                                )}
                            </div>
                          ))}
                        </div>
                        <div className="col">
                          {obj.rcd.map((obj_i, ind_i) => (
                            <div key={ind_i}>
                              {obj_i.formView.position === "right" &&
                                obj_i.uivalid.visible === "true" && (
                                  <span key={ind_i}>
                                    <FormInnerCompo
                                      obj={obj_i}
                                      m_rid={m_rid}
                                      col_depend={column_depend}
                                      setContextMenu={setContextMenu}
                                      formChangefn={formChangefn}
                                      setMSC={setMSC}
                                      validationfn={validationfn}
                                      passwordReadOnly={passwordReadOnly}
                                      tableName={tableName.current}
                                      selection={selection}
                                      setShowSuggestion={setShowSuggestion}
                                      getCaretPos={getCaretPos}
                                      setY={setY}
                                      setX={setX}
                                      recordSuggestion={recordSuggestion}
                                      userRecordSuggestion={
                                        userRecordSuggestion
                                      }
                                      setSearchrcd={setSearchrcd}
                                      searchModule={searchModule}
                                      afterCellEdit={afterCellEdit}
                                      x={x}
                                      y={y}
                                      showSuggestion={showSuggestion}
                                      searchrcd={searchrcd}
                                      ListGroup={ListGroup}
                                      listRef={listRef}
                                      onSelection={onSelection}
                                      clickRefrence={clickRefrence}
                                      getSingleInfo={getSingleInfo}
                                      refrecord={refrecord}
                                      showContainer={showContainer}
                                      setRefRcd={setRefRcd}
                                      showlist={showlist}
                                      col_mn={col_mn}
                                      callfilter={callfilter}
                                      setFiltArray={setFiltArray}
                                      timeline={timeline}
                                      calltimeline={calltimeline}
                                      callScript={callScript}
                                      reScript={reScript}
                                      JoditEditor={JoditEditor}
                                      editor={editor}
                                      choice_mn={choice_mn}
                                      keyValueJson={keyValueJson}
                                      setKeyValueJson={setKeyValueJson}
                                      groupkeyValue={groupkeyValue}
                                      setGroupKeyValue={setGroupKeyValue}
                                      deleteImage={deleteImage}
                                      launchChoose={launchChoose}
                                      handleFileChange={handleFileChange}
                                      handleFileChangeForVideo={
                                        handleFileChangeForVideo
                                      }
                                      verifyError={verifyError}
                                      current_Col_Types={current_Col_Types}
                                      dcFiltVal={dcFiltVal}
                                      deftimeline={deftimeline}
                                      setRecord={setRecord}
                                      isClick={isClick}
                                      page_error={page_error}
                                      handlePdfFileChange={handlePdfFileChange}
                                      record={record}
                                    ></FormInnerCompo>
                                  </span>
                                )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="form-group">
                {tableName.current === "local_user" && rty === "new" && (
                  <button
                    className=" insrtbtn2 btn btn-primary "
                    onClick={(e) => {
                      generatePassword();
                    }}
                  >
                    generate password
                  </button>
                )}

                {button.map((obj, oo_i) => (
                  <button
                    className=" insrtbtn2 btn btn-primary btn-sm sub-btn-form"
                    key={oo_i}
                    onClick={() => handleButtonClick(obj.value)}
                    style={{ backgroundColor: isClick ? "gray" : "" }}
                    disabled={isClick}
                  >
                    {obj.value}
                  </button>
                ))}
              </div>
              {searchParam.get("rty") !== "new" && downRcdView.length > 0 && (
                <div>
                  <hr />
                  {downRcdView.map((obj, index) => (
                    <div className="container" key={index}>
                      <h4 className="text-start mb-3">Activities :</h4>
                      {obj.uivalid.visible === "true" &&
                        activity.map((obj, ind) => (
                          <div key={ind} className=" my-2">
                            <div className="card">
                              <div className="card-header d-flex justify-content-between">
                                <div>
                                  <i
                                    className="fa fa-user-circle-o me-2 mt-1"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Profile"
                                    aria-hidden="false"
                                  ></i>
                                  <span className="name">{obj.noteBy}</span>
                                </div>
                                <span className="type">{obj.columnName}</span>
                              </div>
                              <div className="card-body text-start">
                                <div className="content mb-2">
                                  <b>{obj.columnName} </b>
                                  {obj.content
                                    .replace(obj.columnName, "")
                                    .trim()}
                                </div>
                                <div className="footer">{obj.update}</div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              )}

              <div style={{ position: "absolute", width: "100%" }}>
                <div>
                  {tabRel === true &&
                    tabRelation !== undefined &&
                    Object.keys(tabRelation).length !== 0 &&
                    rty.current === "record" && (
                      <div>
                        {tabRelation.relation.length > 0 ? (
                          <div className="mt-4">
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey={
                                tabRelation.relation[0].formRecordList[1].table
                                  .label
                              }
                            >
                              <Nav variant="pills" className="flex-column">
                                <div className="rel_flex rw">
                                  {tabRelation.relation.map((nama, tab_ia) => (
                                    <div md={2} key={tab_ia} className="nopad">
                                      {isMobile ? (
                                        <Nav.Item className="cur" color="black">
                                          <Nav.Link
                                            className=""
                                            eventKey={
                                              nama.formRecordList[1].table.label
                                            }
                                          >
                                            {nama.formRecordList[3].page
                                              .record_count !== "0"
                                              ? nama.formRecordList[1].table
                                                  .label +
                                                " (" +
                                                nama.formRecordList[3].page
                                                  .record_count +
                                                ")"
                                              : nama.formRecordList[1].table
                                                  .label}
                                          </Nav.Link>
                                        </Nav.Item>
                                      ) : (
                                        <Nav.Item className="cur" color="black">
                                          <Nav.Link
                                            eventKey={
                                              nama.formRecordList[1].table.label
                                            }
                                          >
                                            {nama.formRecordList[3].page
                                              .record_count !== "0"
                                              ? nama.formRecordList[1].table
                                                  .label +
                                                " (" +
                                                nama.formRecordList[3].page
                                                  .record_count +
                                                ")"
                                              : nama.formRecordList[1].table
                                                  .label}
                                          </Nav.Link>
                                        </Nav.Item>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </Nav>

                              <Tab.Content>
                                {tabRelation.relation.map((nam, tab_i) => (
                                  <Tab.Pane
                                    key={tab_i}
                                    eventKey={nam.formRecordList[1].table.label}
                                  >
                                    <RelationList
                                      all_ref_cols={all_ref_cols}
                                      recordList={nam}
                                      tableName={
                                        nam.formRecordList[1].table.name
                                      }
                                      i_d={i_d.current}
                                      prevTab={tableName.current}
                                      rd={record}
                                    ></RelationList>
                                  </Tab.Pane>
                                ))}
                              </Tab.Content>
                            </Tab.Container>
                          </div>
                        ) : null}
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal show={showModelList} onHide={cancelModelList} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Personalized List Column</Modal.Title>
          </Modal.Header>
          <Modal.Body className="mod-ht">
            <ModelList
              setRef={setRef}
              columnid={columnId}
              onCancel={cancelModelList}
              previousFilter={ref_filter}
              previousTimeLine={""}
              tableName={objLabel}
              objName={objName}
              col_rcds={record}
              setshowModelList={setshowModelList}
              isKV={isKV}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancelModelList}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Block navigation modal  */}
        <Modal backdrop show={blocker.state === "blocked"}>
          <Modal.Body className="pb-0">
            <h5 className="text-secondary">
              Your unsaved changes will be lost! Still leave?
            </h5>
          </Modal.Body>
          <Modal.Footer className="p-2 border-top-0">
            <Button
              size="sm"
              variant="danger"
              onClick={() => {
                setShouldBlock(false);
                blocker.proceed();
              }}
            >
              Yes
            </Button>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => blocker.reset()}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {labelData && <InvoiceLabel data={labelData} />}
        {showModelInfo === true && (
          <DragModel
            setShowModelInfo={setShowModelInfo}
            rcdView={infoRcdView}
            showModelInfo={showModelInfo}
            closeRef={closeRef}
            rid={rid}
            m_rid={m_rid}
            tableName={tableName}
            infoRcdDetail={infoRcdDetail}
            selection={selection}
            setShowSuggestion={setShowSuggestion}
            getCaretPos={getCaretPos}
            setY={setY}
            setX={setX}
            recordSuggestion={recordSuggestion}
            userRecordSuggestion={userRecordSuggestion}
            setSearchrcd={setSearchrcd}
            searchModule={searchModule}
            afterCellEdit={afterCellEdit}
            x={x}
            y={y}
            showSuggestion={showSuggestion}
            searchrcd={searchrcd}
            ListGroup={ListGroup}
            getSingleInfo={getSingleInfo}
            refrecord={refrecord}
            showContainer={showContainer}
            setRefRcd={setRefRcd}
            showlist={showlist}
            col_mn={col_mn}
            callfilter={callfilter}
            column_depend={column_depend}
            dcFiltVal={dcFiltVal}
            setFiltArray={setFiltArray}
            timeline={timeline}
            calltimeline={calltimeline}
            callScript={callScript}
            reScript={reScript}
            deleteImage={deleteImage}
            handleFileChange={handleFileChange}
            setImgObj={setImgObj}
            imgObj={imgObj}
            launchChoose={launchChoose}
            JoditEditor={JoditEditor}
            editor={editor}
            choice_mn={choice_mn}
            choice_ref_mn={choice_ref_mn}
            verifyError={verifyError}
            multiSv={multiSv}
            add={add}
            deftimeline={deftimeline}
            isMobile={isMobile}
            filtRefArray={filtRefArray}
            setFiltRefArray={setFiltRefArray}
            setGroupKeyValue={setGroupKeyValue}
          ></DragModel>
        )}
      </div>
    );
  } else {
    return <WorkInProgress />;
  }
};

export default FormComponent;
